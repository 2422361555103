import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const navigate = useNavigate();

  const handleSubscribe = (event) => {
    event.preventDefault();
    alert("Subscribed with email!");
  };

  return (
    <footer className="footer">
      <div className="footer-contact">
        <h3>Contact Us</h3>
        <p>
          9813 Fair Oaks Blvd, Suite A<br />
          Fair Oaks, CA 95628
        </p>
        <p>Phone: 916 784 9809</p>
        <p>info@pathwaystoemploymentgb.org</p>
        <a href="#!" onClick={() => navigate("/policies")}>
          Policies
        </a>{" "}
      </div>
      <div className="footer-social">
        <h3>Connect With Us</h3>
        <a
          href="https://www.facebook.com/pathwaysempcorp/"
          className="social-icon"
        >
          <i className="fab fa-facebook-f"></i>
        </a>
        <a
          href="https://www.instagram.com/pathwaystoemploymentgb/"
          className="social-icon"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <p className="website-credit">
          Website created by{" "}
          <a href="https://www.davebenner.ca/" className="credit-name">
            David Benner
          </a>
        </p>
      </div>
      <div className="footer-subscribe">
        <h3>Subscribe</h3>
        <form onSubmit={handleSubscribe}>
          <input type="email" placeholder="name@example.com" required />
          <button type="submit">Subscribe</button>
        </form>
        <div className="seal-image-container">
          <img
            src="/Assets/Photos/candid-seal-gold-2024.png"
            alt="Candid Seal"
            className="seal-image"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
