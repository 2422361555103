import React, { useState } from "react";
import { useCart } from "../CartContext/CartContext";
import { Link } from "react-router-dom";
import logo from "../../Assets/Logo/PtELogo.webp";
import "bootstrap/dist/css/bootstrap.min.css";
import "./NavBar.css";

const NavBar = () => {
  const [activeDropdown, setActiveDropdown] = useState("");
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const isLoggedIn = localStorage.getItem("token");
  const { cartItems } = useCart();

  const showDropdown = (dropdownName) => {
    setActiveDropdown(dropdownName);
  };

  const hideDropdown = () => {
    setActiveDropdown("");
  };

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
          id="navbarNav"
        >
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                HOME
              </Link>
            </li>
            <li
              className={`nav-item dropdown ${
                activeDropdown === "about" ? "show" : ""
              }`}
              onMouseEnter={() => showDropdown("about")}
              onMouseLeave={hideDropdown}
            >
              <Link
                to="/aboutUs"
                className="nav-link dropdown-toggle"
                id="navbarDropdownAbout"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={activeDropdown === "about" ? "true" : "false"}
              >
                ABOUT US
              </Link>
              <div
                className={`dropdown-menu ${
                  activeDropdown === "about" ? "show" : ""
                }`}
                aria-labelledby="navbarDropdownAbout"
              >
                <Link
                  to="/aboutUs#board"
                  className="dropdown-item"
                  onClick={hideDropdown}
                >
                  Board of Directors
                </Link>
                <Link
                  to="/aboutUs#partners"
                  className="dropdown-item"
                  onClick={hideDropdown}
                >
                  Partners & Supporters
                </Link>
              </div>
            </li>
            <li
              className={`nav-item dropdown ${
                activeDropdown === "services" ? "show" : ""
              }`}
              onMouseEnter={() => showDropdown("services")}
              onMouseLeave={hideDropdown}
            >
              <Link
                to="/services"
                className="nav-link dropdown-toggle"
                id="navbarDropdownServices"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={activeDropdown === "services" ? "true" : "false"}
              >
                SERVICES
              </Link>
              <div
                className={`dropdown-menu ${
                  activeDropdown === "services" ? "show" : ""
                }`}
                aria-labelledby="navbarDropdownServices"
              >
                <Link
                  to="/monthlySchedule"
                  className="dropdown-item"
                  onClick={hideDropdown}
                >
                  Monthly Schedule
                </Link>
                <Link
                  to="/services#classDescriptions"
                  className="dropdown-item"
                  onClick={hideDropdown}
                >
                  Class Descriptions
                </Link>
              </div>
            </li>
            <li className="nav-item">
              <Link to="/contact" className="nav-link">
                CONTACT
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/cart" className="nav-link cart-icon-link">
                <svg
                  className="cart-icon"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="9" cy="21" r="1"></circle>
                  <circle cx="20" cy="21" r="1"></circle>
                  <path d="M1 1h4l2.6 12.59a2 2 0 001.92 1.41H19a2 2 0 001.98-1.67L23 6H6"></path>
                </svg>
                {cartItems.length > 0 && (
                  <span className="cart-count">{cartItems.length}</span>
                )}
              </Link>
            </li>
            <li className="nav-item">
              {isLoggedIn ? (
                <Link to="/userProfile" className="nav-link">
                  <img
                    src="/Assets/Photos/User/User.png"
                    alt="User"
                    className="user-icon"
                  />
                </Link>
              ) : (
                <Link to="/login" className="nav-link">
                  Log In
                </Link>
              )}
            </li>
            <li className="nav-item">
              <Link to="/donate" className="nav-link donate-button">
                Donate
                <span
                  dangerouslySetInnerHTML={{
                    __html: `
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="-5 0 28 18" fill="white" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-heart icon">
                <path d="M20.8 4.6c-1.4-1.4-3.6-1.4-5 0L12 8.4l-3.8-3.8c-1.4-1.4-3.6-1.4-5 0s-1.4 3.6 0 5L12 21.6l8.8-8.8c1.4-1.4 1.4-3.6 0-5z"></path>
              </svg>
              `
                  }}
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
