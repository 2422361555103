import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import NavBar from "./components/NavBar/NavBar";
import HeroSection from "./components/HeroSection/HeroSection";
import MissionSection from "./components/MissionSection/MissionSection";
import DynamicSection from "./components/DynamicSection/DynamicSection";
import MonthlySchedule from "./components/MonthlySchedule/MonthlySchedule";
import AboutUs from "./components/AboutUs/AboutUs";
import Services from "./components/Services/Services";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import Login from "./components/Login/Login";
import Donate from "./components/Donate/Donate";
import Policies from "./components/Policies/Policies";
import UserProfile from "./components/UserProfile/UserProfile";
import AdminDashboard from "./components/AdminDashboard/AdminDashboard";
import Register from "./components/Register/Register";
import RegistrationSuccess from "./components/RegistrationSuccess/RegistrationSuccess";
import ParticipationForm from "./components/ParticipationForm/ParticipationForm";
import ClassScheduler from "./components/ClassScheduler/ClassScheduler";
import { CartProvider } from "./components/CartContext/CartContext";
import CartPage from "./components/CartPage/CartPage";
import StripeCheckoutPage from "./components/CartPage/StripeCheckoutPage";
import { ClassesProvider } from "./components/ClassesContext/ClassesContext";
import ClassLists from "./components/UserProfile/ClassLists";
import MyClasses from "./components/UserProfile/MyClasses";
import { Routes, Route, useLocation } from "react-router-dom";
import GoogleAuthTest from "./components/Login/GoogleAuthTest";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { AuthProvider } from "./components/context/AuthContext";
import UserList from "./components/UserProfile/UserList";
import Invoicing from "./components/Invoicing/Invoicing";
import DonationSection from "./components/DonateSection/DonationSection";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function App() {
  const clientId =
    "711154044658-25o8imctgqmdri9drjqktehqairc2sa6.apps.googleusercontent.com";
  const [participationWaiverData, setParticipationWaiverData] = useState([]);
  const [filteredParticipationData, setFilteredParticipationData] = useState(
    []
  );
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = localStorage.getItem("currentPage");
    return storedPage || "home";
  });
  const [events, setEvents] = useState([]);
  const [checkoutData, setCheckoutData] = useState({
    cartItems: [],
    totalAmount: 0
  });
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEventDetails, setSelectedEventDetails] = useState(null);
  const [userId, setUserId] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Automatic Logout Timer
  const logoutTimerRef = useRef(null);

  const handleLogout = () => {
    setCurrentPage("home");
    localStorage.removeItem("token");
    localStorage.setItem("currentPage", "home");
    setUserId(null);
    setUserRole(null);
  };

  const resetLogoutTimer = () => {
    if (logoutTimerRef.current) {
      clearTimeout(logoutTimerRef.current);
    }
    logoutTimerRef.current = setTimeout(handleLogout, 3600000);
  };

  useEffect(() => {
    const fetchParticipationWaivers = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error(
          "No token found, aborting fetch for participation waivers."
        );
        return;
      }

      try {
        const hardcodedBaseURL = "https://www.ptemployment.org/";
        const response = await axios.get(
          `${hardcodedBaseURL}/api/participation-waivers`,
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        );

        console.log(
          "Participation waivers fetched successfully:",
          response.data
        );
        setParticipationWaiverData(response.data);
      } catch (error) {
        console.error("Error fetching participation waivers:", error);
      }
    };

    console.log(`Navigated to: ${location.pathname}`);
    fetchParticipationWaivers();
    resetLogoutTimer();
  }, [location.pathname]);

  useEffect(() => {
    switch (location.pathname) {
      case "/userProfile":
        console.log(
          "UserProfile component is active. Check for updates if needed."
        );
        break;
      case "/adminDashboard":
        console.log(
          "AdminDashboard component is active. Ensure the data fetching logic is up to date."
        );
        break;
      default:
        console.log(`Navigated to ${location.pathname}`);
    }
    resetLogoutTimer();
  }, [location]);

  useEffect(() => {
    if (selectedEventId != null) {
      const newFilteredData = participationWaiverData.filter(
        (waiver) => waiver?.event_id?.toString() === selectedEventId.toString()
      );
      setFilteredParticipationData(newFilteredData);
    } else {
      setFilteredParticipationData([]);
    }
    resetLogoutTimer();
  }, [selectedEventId, participationWaiverData]);

  const aboutUsRefs = {
    board: useRef(null),
    partners: useRef(null),
    sponsors: useRef(null)
  };

  const handleCheckout = (cartItems, totalAmount) => {
    setCheckoutData({ cartItems, totalAmount, userId });
    setCurrentPage("stripeCheckout");
    resetLogoutTimer();
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      setUserRole(decoded.role);
      setUserId(decoded.user_id);
    }
    resetLogoutTimer();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    fetchEvents();
    const handlePageChange = () => {
      const storedPage = localStorage.getItem("currentPage");
      if (storedPage) {
        setCurrentPage(storedPage);
      }
    };

    window.addEventListener("storage", handlePageChange);

    return () => window.removeEventListener("storage", handlePageChange);
  }, []);

  const fetchEvents = async () => {
    try {
      const token = localStorage.getItem("token");
      const config = token
        ? { headers: { Authorization: `Bearer ${token}` } }
        : {};

      const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3000";

      const response = await axios.get(
        `https://www.ptemployment.org/api/class-schedules`,
        config
      );

      const formattedEvents = Array.isArray(response.data)
        ? response.data.map((event) => ({
            ...event,
            start: new Date(event.start_time),
            end: new Date(event.end_time)
          }))
        : [];

      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const navigate = (page, eventId = null) => {
    setCurrentPage(page);
    setSelectedEventId(eventId);

    if (Array.isArray(events) && eventId !== null) {
      const eventDetails = events.find(
        (event) => event.id.toString() === eventId.toString()
      );

      if (eventDetails) {
        setSelectedEventDetails({
          title: eventDetails.title,
          date: new Date(eventDetails.start_time).toLocaleDateString(),
          time: new Date(eventDetails.start_time).toLocaleTimeString()
        });
      } else {
        console.error(`Event with ID ${eventId} not found.`);
        setSelectedEventDetails(null);
      }
    } else {
      setSelectedEventDetails(null);
    }

    localStorage.setItem("currentPage", page);
    resetLogoutTimer();
  };

  useEffect(() => {
    const events = ["click", "mousemove", "keypress"];
    events.forEach((event) => window.addEventListener(event, resetLogoutTimer));

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetLogoutTimer)
      );
    };
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <AuthProvider>
        <CartProvider>
          <ClassesProvider>
            <div className="App">
              <NavBar />
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <HeroSection />
                      <MissionSection />
                      <DonationSection />
                      <DynamicSection />
                    </>
                  }
                />
                <Route
                  path="/monthlySchedule"
                  element={<MonthlySchedule events={events} />}
                />
                <Route
                  path="/aboutUs"
                  element={<AboutUs ref={aboutUsRefs} />}
                />
                <Route path="/services" element={<Services />} />
                <Route
                  path="/classScheduler"
                  element={
                    <ClassScheduler events={events} setEvents={setEvents} />
                  }
                />
                <Route path="/classLists" element={<ClassLists />} />
                <Route path="/userList/:eventId" element={<UserList />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/google-auth-test" element={<GoogleAuthTest />} />
                <Route path="/login" element={<Login />} />
                <Route path="/donate" element={<Donate />} />
                <Route path="/register" element={<Register />} />
                <Route path="/policies" element={<Policies />} />
                <Route path="/myClasses" element={<MyClasses />} />
                <Route
                  path="/participationForm"
                  element={<ParticipationForm />}
                />
                <Route path="/userProfile" element={<UserProfile />} />
                <Route path="/invoicing" element={<Invoicing />} />

                <Route path="/adminDashboard" element={<AdminDashboard />} />
                <Route
                  path="/cart"
                  element={
                    <CartPage onCheckout={handleCheckout} userId={userId} />
                  }
                />
                <Route
                  path="/stripeCheckout"
                  element={
                    <StripeCheckoutPage
                      cartItems={checkoutData.cartItems}
                      totalAmount={checkoutData.totalAmount}
                      userId={userId}
                      eventId={selectedEventId}
                    />
                  }
                />
                <Route
                  path="/registration-success"
                  element={<RegistrationSuccess />}
                />
              </Routes>
              <Footer />
            </div>
          </ClassesProvider>
        </CartProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
