import React, { useState, useEffect, useContext } from "react";
import { ClassesContext } from "../ClassesContext/ClassesContext";
import axios from "axios";
import EventDetailsPopup from "../EventDetailsPopup/EventDetailsPopup";
import "./ClassScheduler.css";
import RecurringDatePicker from "../RecurringDatePicker/RecurringDatePicker";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";

const ClassScheduler = ({ events, setEvents }) => {
  const navigate = useNavigate();
  const now = new Date();
  const oneHourLater = new Date(now.getTime() + 60 * 60 * 1000);

  const formatDateTimeLocalForPT = (date) => {
    let momentDate = moment(date).tz("America/Los_Angeles");
    return momentDate.format("YYYY-MM-DDTHH:mm");
  };

  const [eventData, setEventData] = useState({
    title: "",
    start_time: formatDateTimeLocalForPT(now),
    end_time: formatDateTimeLocalForPT(oneHourLater),
    description: "",
    price: "",
    hours: "", // New field for Hours
    description_url: "",
    event_color: "",
    is_all_day: false,
    image_url: ""
  });

  const localizer = momentLocalizer(moment);

  const [isAutofilled, setIsAutofilled] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventForm, setShowEventForm] = useState(false);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [recurringDates, setRecurringDates] = useState([]);
  const { classes, setClasses } = useContext(ClassesContext);
  const [selectedClassId, setSelectedClassId] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const uniqueClassTitles = Array.isArray(classes)
    ? Array.from(new Set(classes.map((event) => event.title)))
    : [];

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsEndpoint =
          "https://www.ptemployment.org/api/classes/class-schedules";

        const response = await axios.get(eventsEndpoint, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        });
        console.log("Fetched events:", response.data); // Add log here
        setEvents(response.data);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };
    fetchEvents();
  }, [setEvents]);

  const fetchClasses = async () => {
    const classesEndpoint =
      "https://www.ptemployment.org/api/classes/class-schedules";
    try {
      const response = await axios.get(classesEndpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      console.log("Classes fetched:", response.data);
      if (Array.isArray(response.data)) {
        setClasses(response.data);
      } else {
        console.error("Fetched data is not an array:", response.data);
        setClasses([]);
      }
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const CustomEvent = ({ event }) => {
    return (
      <div>
        <div>{event.title}</div>
        <div>
          {formatTimeFromString(event.originalStartTime)} -{" "}
          {formatTimeFromString(event.originalEndTime)}
        </div>
      </div>
    );
  };

  const handleAutofill = () => {
    const selectedEvent = classes.find(
      (event) => event.title === selectedClassId
    );
    if (selectedEvent) {
      setEventData({
        ...eventData,
        title: selectedEvent.title,
        description: selectedEvent.description,
        price: selectedEvent.price,
        hours: selectedEvent.hours, // Autofill hours field
        image_url: selectedEvent.image_url
      });
      setIsAutofilled(true);
    }
  };

  const eventsForCalendar = events.map((event) => ({
    ...event,
    start: new Date(event.start_time),
    end: new Date(event.end_time),
    originalStartTime: event.start_time,
    originalEndTime: event.end_time
  }));

  console.log("Events for calendar:", eventsForCalendar); // Add log here

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newEventData = {
      ...eventData,
      [name]: type === "checkbox" ? checked : value
    };

    if (name === "start_time" && value) {
      const startTime = new Date(value);
      const endTime = new Date(startTime.getTime() + 60 * 60 * 1000);
      newEventData.end_time = endTime.toISOString().slice(0, 16);
    }

    setEventData(newEventData);
  };

  const handleClassSelectionChange = (e) => {
    setSelectedClassId(e.target.value);
  };

  const handleDateSelect = (date) => {
    const dateParts = date.split("-");
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);

    const selectedDate = new Date(Date.UTC(year, month, day));

    const formattedDate = selectedDate.toISOString().slice(0, 10);

    setRecurringDates((prevDates) =>
      prevDates.includes(formattedDate)
        ? prevDates.filter((d) => d !== formattedDate)
        : [...prevDates, formattedDate]
    );
  };

  const pastelColors = [
    // Reds (10)
    { name: "Pastel Blush", color: "#ffc3a0" },
    { name: "Pastel Salmon", color: "#ffb0ac" },
    { name: "Pastel Pink", color: "#ffb6c1" },

    { name: "Pastel Rose", color: "#ff6f61" },
    { name: "Pastel Red", color: "#ff6961" },
    { name: "Pastel Coral", color: "#ff6b81" },

    { name: "Pastel Ruby", color: "#e75480" },
    { name: "Crimson", color: "#dc143c" },
    { name: "Regular Red", color: "#ff0000" },

    { name: "Dark Red", color: "#8b0000" },

    // Oranges (9)
    { name: "Pastel Peach", color: "#ffcc99" },
    { name: "Pastel Tangerine", color: "#ffcc80" },
    { name: "Pastel Orange", color: "#ffbb91" },
    { name: "Light Orange", color: "#ffd580" },
    { name: "Amber", color: "#ffbf00" },
    { name: "Regular Orange", color: "#ffa500" },
    { name: "Dark Orange", color: "#ff8c00" },
    { name: "Vivid Orange", color: "#ff4500" },
    { name: "Burnt Orange", color: "#cc5500" },

    // Yellows (8)
    { name: "Light Yellow", color: "#ffffe0" },
    { name: "Pastel Lemon", color: "#fff44f" },
    { name: "Pastel Yellow", color: "#fdfd96" },
    { name: "Regular Yellow", color: "#ffff00" },
    { name: "Mustard Yellow", color: "#ffdb58" },
    { name: "Pastel Gold", color: "#ffd700" },
    { name: "Dark Yellow", color: "#ffcc00" },
    { name: "Goldenrod", color: "#daa520" },

    // Greens (9)
    { name: "Pastel Moss Green", color: "#addfad" },
    { name: "Pastel Lime", color: "#bfff00" },
    { name: "Pastel Mint", color: "#98ff98" },
    { name: "Pastel Green", color: "#77dd77" },
    { name: "Regular Green", color: "#00ff00" },
    { name: "Lime Green", color: "#32cd32" },
    { name: "Olive Green", color: "#808000" },
    { name: "Forest Green", color: "#228b22" },
    { name: "Dark Green", color: "#006400" },

    // Blues (including Cyan) (10)
    { name: "Pastel Azure", color: "#f0ffff" },
    { name: "Pastel Periwinkle", color: "#c3cde6" },
    { name: "Pastel Blue", color: "#aec6cf" },
    { name: "Pastel Aqua", color: "#7fffd4" },
    { name: "Pastel Teal", color: "#99ffcc" },
    { name: "Pastel Turquoise", color: "#40e0d0" },
    { name: "Pastel Sky", color: "#87ceeb" },
    { name: "Cyan", color: "#00ffff" },
    { name: "Regular Blue", color: "#0000ff" },
    { name: "Dark Blue", color: "#00008b" },

    // Purples (including Violet and Magenta) (10)
    { name: "Pastel Lavender Blush", color: "#fff0f5" },
    { name: "Lavender", color: "#e6e6fa" },
    { name: "Pastel Lavender", color: "#e6e6fa" },
    { name: "Pastel Purple", color: "#b39eb5" },
    { name: "Pastel Lilac", color: "#c8a2c8" },
    { name: "Pastel Plum", color: "#dda0dd" },
    { name: "Violet", color: "#ee82ee" },
    { name: "Pastel Orchid", color: "#db70db" },
    { name: "Magenta", color: "#ff00ff" },
    { name: "Regular Purple", color: "#800080" }
  ];

  const handleColorSelect = (color) => {
    setEventData({ ...eventData, event_color: color });
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setShowEventDetails(true);
  };

  const handleCloseDetails = () => {
    setShowEventDetails(false);
    setSelectedEvent(null);
  };

  const handleEditEvent = async (updatedEvent) => {
    try {
      const updateEndpoint =
        "https://www.ptemployment.org/api/classes/class-schedules/" +
        updatedEvent.id;

      const response = await axios.put(updateEndpoint, updatedEvent, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });
      setEvents(
        events.map((event) =>
          event.id === updatedEvent.id ? response.data : event
        )
      );
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      const response = await axios.delete(
        `https://www.ptemployment.org/api/classes/class-schedules/${eventId}`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
        }
      );
      if (response.status === 200 || response.status === 204) {
        setEvents((prevEvents) =>
          prevEvents.filter((event) => event.id !== eventId)
        );
        alert("Event deleted successfully.");
      } else {
        console.error(`Unexpected response status: ${response.status}`);
        alert("Failed to delete the event. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      alert("Failed to delete the event. Please try again.");
    }
  };

  const toggleEventForm = () => {
    setShowEventForm(!showEventForm);
  };

  const formatTimeFromString = (timeString) => {
    if (!timeString) return "";
    const timePart = timeString.split("T")[1].substring(0, 5);
    const [hours, minutes] = timePart.split(":").map(Number);
    const isPm = hours >= 12;
    const formattedHours = ((hours + 11) % 12) + 1;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${isPm ? "PM" : "AM"}`;
  };

  const ColorPicker = ({ selectedColor, onSelectColor }) => {
    return (
      <div className="color-picker">
        {pastelColors.map((colorOption) => (
          <div
            key={colorOption.color}
            className={`color-option ${
              selectedColor === colorOption.color ? "selected" : ""
            }`}
            style={{ backgroundColor: colorOption.color }}
            onClick={() => onSelectColor(colorOption.color)}
            title={colorOption.name}
          />
        ))}
      </div>
    );
  };

  const eventStyleGetter = (event) => {
    let backgroundColor = event.event_color || "#3174ad";
    return {
      style: { backgroundColor }
    };
  };

  const [showRecurringForm, setShowRecurringForm] = useState(false);

  const handleRecurringClick = () => {
    setShowRecurringForm((prevShowRecurringForm) => !prevShowRecurringForm);
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleImageUpload = async () => {
    if (imageFile) {
      const formData = new FormData();
      formData.append("file", imageFile);

      try {
        const uploadEndpoint = "https://www.ptemployment.org/upload";
        const uploadResponse = await axios.post(uploadEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          responseType: "text"
        });

        if (uploadResponse.status === 200) {
          console.log("Image uploaded successfully:", uploadResponse.data);
          const imageUrl = uploadResponse.data.split(" to ")[1];
          setEventData((prevEventData) => ({
            ...prevEventData,
            image_url: imageUrl
          }));
          console.log("Image URL set to:", imageUrl); // Log the image URL
          return imageUrl; // Return the image URL
        }
      } catch (uploadError) {
        console.error("Error uploading image:", uploadError);
      }
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure "Hours" field is filled out
    if (eventData.hours.trim() === "") {
      alert("Please enter the number of hours.");
      return;
    }

    const imageUrl = await handleImageUpload(); // Ensure image is uploaded and URL is set in eventData
    const eventDataToSubmit = {
      ...eventData,
      image_url: imageUrl || eventData.image_url, // Ensure image URL is set
      price: eventData.price === "" ? null : eventData.price
    };

    console.log("Submitting eventData:", eventDataToSubmit); // Log eventData to verify
    await submitEvent(eventDataToSubmit); // Ensure submitEvent uses updated eventData
  };

  const submitEvent = async (eventData) => {
    try {
      const postEndpoint =
        "https://www.ptemployment.org/api/classes/class-schedules";
      const response = await axios.post(postEndpoint, eventData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.status === 201) {
        console.log("Event successfully added:", response.data);
        setEvents((prevEvents) => [...prevEvents, response.data]);
      }
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  const handleConfirmRecurringDates = async () => {
    const timezoneOffset = new Date().getTimezoneOffset();

    console.log("Initial eventData:", eventData);
    console.log("Recurring dates before deduplication:", recurringDates);

    const uniqueDates = new Set(recurringDates);

    const initialDate = new Date(eventData.start_time)
      .toISOString()
      .slice(0, 10);
    uniqueDates.delete(initialDate);

    console.log("Initial date:", initialDate);
    console.log("Unique dates after deduplication:", uniqueDates);

    const imageUrl = await handleImageUpload(); // Ensure image is uploaded and URL is set in eventData

    const initialEvent = {
      ...eventData,
      image_url: imageUrl || eventData.image_url,
      price: eventData.price === "" ? null : eventData.price
    };

    console.log("Submitting initial event:", initialEvent);
    await submitEvent(initialEvent);

    for (const date of uniqueDates) {
      const [year, month, day] = date.split("-").map(Number);

      const startTime = new Date(eventData.start_time);
      startTime.setFullYear(year, month - 1, day);
      startTime.setMinutes(startTime.getMinutes() - timezoneOffset);

      const endTime = new Date(eventData.end_time);
      endTime.setFullYear(year, month - 1, day);
      endTime.setMinutes(endTime.getMinutes() - timezoneOffset);

      const newEvent = {
        ...eventData,
        start_time: startTime.toISOString(),
        end_time: endTime.toISOString(),
        image_url: imageUrl || eventData.image_url,
        price: eventData.price === "" ? null : eventData.price
      };

      console.log("Submitting recurring event:", newEvent);
      await submitEvent(newEvent);
    }

    setRecurringDates([]);
    setShowRecurringForm(false);
  };

  return (
    <div className="class-scheduler">
      <div className="mb-3">
        <button
          className="btn btn-primary"
          onClick={() => navigate("/userProfile")}
        >
          Back to Profile
        </button>
      </div>

      <h1>Class Scheduler</h1>
      <div className="class-scheduler-form-container">
        <div className="class-selection-autofill-container">
          <select
            value={selectedClassId}
            onChange={handleClassSelectionChange}
            className="class-selection-dropdown"
          >
            <option value="">Select a Previous Class</option>
            {uniqueClassTitles.map((title, index) => (
              <option key={index} value={title}>
                {title}
              </option>
            ))}
          </select>

          <button className="autofill-button" onClick={handleAutofill}>
            Autofill From Selected Class
          </button>
        </div>

        <form onSubmit={handleSubmit} className="class-scheduler-form">
          <input
            className="form-input"
            type="text"
            name="title"
            value={eventData.title}
            onChange={handleChange}
            placeholder="Class Title"
            required
          />
          {!isAutofilled && (
            <>
              <label htmlFor="image-upload" className="form-label">
                Event Image:
              </label>
              <input
                type="file"
                id="image-upload"
                onChange={handleImageChange}
                accept="image/*"
              />
            </>
          )}
          <div className="disclaimer">
            <p>
              If you are planning to make this event recurring, the specific
              date you choose here does not impact the recurrence schedule, as
              you can select the dates directly from the calendar below.
              However, the start and end times you set here will apply to all
              occurrences, so please ensure they reflect your intended schedule.
            </p>
          </div>
          <label className="form-label">Start Time:</label>
          <input
            className="form-input"
            type="datetime-local"
            name="start_time"
            value={eventData.start_time}
            onChange={handleChange}
            required
          />
          <label className="form-label">End Time:</label>
          <input
            className="form-input"
            type="datetime-local"
            name="end_time"
            value={eventData.end_time}
            onChange={handleChange}
            required
          />
          <textarea
            className="form-textarea description-textarea"
            name="description"
            value={eventData.description}
            onChange={handleChange}
            placeholder="Description"
          />
          <input
            className="form-input"
            type="number"
            name="price"
            value={eventData.price}
            onChange={handleChange}
            placeholder="Price"
          />
          <input
            className="form-input"
            type="number"
            name="hours"
            value={eventData.hours}
            onChange={handleChange}
            placeholder="Hours" // New input field for Hours
            required // Making the field mandatory
          />
          <input
            type="hidden"
            name="image_url"
            value={eventData.image_url}
            onChange={handleChange}
          />
          <label className="form-label">Event Color:</label>
          <ColorPicker
            selectedColor={eventData.event_color}
            onSelectColor={handleColorSelect}
          />
          <button
            type="button"
            className="form-button"
            onClick={handleRecurringClick}
          >
            Make Recurring
          </button>
          {showRecurringForm && (
            <>
              <div className="recurring-section">
                <p className="disclaimer">
                  Click on the days of the month that you want this event to be
                  recurring. Selected days will highlight in green. Then, click
                  on "Confirm Recurring Dates" to confirm.
                </p>

                <RecurringDatePicker
                  onDateSelect={handleDateSelect}
                  selectedDates={recurringDates}
                />
              </div>
              <button
                type="button"
                className="form-button"
                onClick={handleConfirmRecurringDates}
              >
                Confirm Recurring Dates
              </button>
            </>
          )}
          {!showRecurringForm && (
            <button className="form-submit-button" type="submit">
              Add Class
            </button>
          )}
        </form>
      </div>
      <Calendar
        localizer={localizer}
        events={eventsForCalendar}
        onSelectEvent={handleSelectEvent}
        eventPropGetter={eventStyleGetter}
        style={{ height: "auto", minHeight: "1300px" }}
        views={["month"]}
        components={{
          event: CustomEvent
        }}
      />

      {showEventDetails && selectedEvent && (
        <EventDetailsPopup
          event={selectedEvent}
          onEdit={handleEditEvent}
          onDelete={handleDeleteEvent}
          onClose={handleCloseDetails}
        />
      )}
    </div>
  );
};

export default ClassScheduler;
