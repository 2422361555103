import React from "react";
import { useNavigate } from "react-router-dom";
import "./Policies.css";

const Policies = () => {
  const isLoggedIn = localStorage.getItem("token");
  const navigate = useNavigate();
  const pdfIcon = "/Assets/Photos/Policies/pdf.webp";

  return (
    <div className="policies-container">
      {isLoggedIn && (
        <div className="back-to-profile-top mb-5">
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        </div>
      )}
      <h1>Policies</h1>

      <section>
        <h2>Support Staff Expectations</h2>
        <a
          href="/Assets/Photos/Policies/support_staff_expectations.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={pdfIcon} alt="PDF" /> Support Staff Expectations
        </a>
      </section>

      <section>
        <h2>Violation Policy</h2>
        <a
          href="/Assets/Photos/Policies/VIOLATION_POLICY.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={pdfIcon} alt="PDF" /> Violation Policy
        </a>
      </section>

      <section>
        <h2>Parent's Lounge</h2>
        <a
          href="/Assets/Photos/Policies/Parents_Lounge.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={pdfIcon} alt="PDF" /> Parent’s Lounge
        </a>
      </section>

      <section>
        <h2>Client Expectations (Coffee Service)</h2>
        <a
          href="/Assets/Photos/Policies/Client_Expectations_Coffee_Service.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={pdfIcon} alt="PDF" /> Client Expectations - Coffee Service
        </a>
      </section>

      <section>
        <h2>Previous Newsletters</h2>
        <a
          href="/Assets/Photos/Policies/Pathways_to_Employment_July_2021_newsletter.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={pdfIcon} alt="PDF" /> July 2021 Newsletter
        </a>
        <a
          href="/Assets/Photos/Policies/Pathways_to_Employment_August_2021_newsletter.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={pdfIcon} alt="PDF" /> August 2021 Newsletter
        </a>
      </section>
      {isLoggedIn && (
        <div className="back-to-profile-bottom mt-4">
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        </div>
      )}
    </div>
  );
};

export default Policies;
