import React, { useState, useEffect, useContext, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CustomCalendar.css";
import EventDetailsPopup from "../EventDetailsPopup/EventDetailsPopup";
import { ClassesContext } from "../ClassesContext/ClassesContext";
import { useNavigate } from "react-router-dom";

const localizer = momentLocalizer(moment);

const MonthlySchedule = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const isLoggedIn = localStorage.getItem("token");
  const navigate = useNavigate();
  const calendarRef = useRef(null);

  const { classes } = useContext(ClassesContext);

  const formattedClasses = classes.map((event) => ({
    ...event,
    start: moment.utc(event.start_time).toDate(),
    end: moment.utc(event.end_time).toDate(),
    title: event.title,
    originalStartTime: event.start_time,
    originalEndTime: event.end_time
  }));

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = event.event_color || "#3174ad";
    return {
      style: {
        backgroundColor,
        borderRadius: "5px",
        opacity: 0.8,
        color: "white",
        border: "0px",
        display: "block",
        overflow: "visible"
      }
    };
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setShowEventDetails(true);
  };

  const handleCloseDetails = () => {
    setShowEventDetails(false);
    setSelectedEvent(null);
  };

  const scrollToToday = () => {
    const calendarElement = calendarRef.current;
    if (calendarElement) {
      const todayElement = calendarElement.querySelector(".rbc-today");
      if (todayElement) {
        todayElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  const CustomEvent = ({ event }) => {
    const formatTimeFromString = (timeString) => {
      if (!timeString) return "";
      const timePart = timeString.split("T")[1].substring(0, 5);
      const [hours, minutes] = timePart.split(":").map(Number);
      const isPm = hours >= 12;
      const formattedHours = ((hours + 11) % 12) + 1;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      return `${formattedHours}:${formattedMinutes} ${isPm ? "PM" : "AM"}`;
    };

    return (
      <div
        style={{
          whiteSpace: "normal",
          overflow: "hidden",
          height: "auto",
          padding: "2px 5px"
        }}
      >
        <strong>{event.title}</strong>
        <div>
          {formatTimeFromString(event.originalStartTime)} -
          {formatTimeFromString(event.originalEndTime)}
        </div>
      </div>
    );
  };

  return (
    <div style={{ paddingTop: "80px" }}>
      {isLoggedIn && (
        <div className="back-to-profile-top mb-4">
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        </div>
      )}
      <h1>Monthly Schedule</h1>
      <button className="btn btn-success my-3" onClick={scrollToToday}>
        Jump to Today
      </button>
      <div style={{ height: "800px", overflowY: "auto" }} ref={calendarRef}>
        <Calendar
          localizer={localizer}
          events={formattedClasses}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={handleSelectEvent}
          eventPropGetter={eventStyleGetter}
          style={{ minHeight: "2400px" }}
          views={["month"]}
          components={{
            event: CustomEvent
          }}
        />
      </div>
      {showEventDetails && selectedEvent && (
        <EventDetailsPopup
          event={selectedEvent}
          onClose={handleCloseDetails}
          displayOnly={true}
        />
      )}
      {isLoggedIn && (
        <div className="back-to-profile-bottom mt-4">
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        </div>
      )}
    </div>
  );
};

export default MonthlySchedule;
