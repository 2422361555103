import React, { createContext, useState, useContext, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
      const decodedToken = jwtDecode(token);
      setUser({ name: decodedToken.username, id: decodedToken.user_id });
      setUserRole(decodedToken.role);
    }
  }, []);

  const login = (userInfo, token) => {
    setIsAuthenticated(true);
    setUser(userInfo);
    localStorage.setItem("token", token);
    const decodedToken = jwtDecode(token);
    setUserRole(decodedToken.role);
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    setUserRole(null);
    localStorage.removeItem("token");
  };

  const getToken = () => {
    return localStorage.getItem("token");
  };
  //

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, user, userRole, login, logout, getToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};
