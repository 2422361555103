import React from "react";
import "./ParticipationForm.css";

const ParticipationFormLayout = ({ formData, handleChange, handleSubmit }) => {
  return (
    <div className="container my-5 custom-margin-top">
      <h1 className="text-center mb-4">
        Participation & Emergency Contact Form
      </h1>

      <form onSubmit={handleSubmit}>
        {/* Participation Section */}
        <h2 className="mb-3">Participation Information</h2>

        <div className="mb-3">
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            className="form-control form-control-centered"
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            className="form-control"
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <input
            type="number"
            name="age"
            placeholder="Age"
            className="form-control"
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <input
            type="text"
            name="address"
            placeholder="Address"
            className="form-control"
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <input
            type="date"
            name="dateOfBirth"
            placeholder="Date of Birth"
            className="form-control"
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="form-control"
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            className="form-control"
            onChange={handleChange}
          />
        </div>

        <div className="form-check mb-3">
          <input
            type="checkbox"
            name="responsibleAdultConsent"
            id="responsibleAdultConsent"
            className="form-check-input"
            checked={formData.responsibleAdultConsent || false}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor="responsibleAdultConsent">
            I understand that a responsible adult must attend with I/DD
            participant, unless otherwise confirmed with Pathway To Employments
            Board.
          </label>
        </div>

        <div className="form-check mb-3">
          <input
            type="checkbox"
            name="photoConsent"
            id="photoConsent"
            className="form-check-input"
            checked={formData.photoConsent}
            onChange={(e) =>
              handleChange({
                target: {
                  name: e.target.name,
                  value: e.target.checked
                }
              })
            }
          />

          <label className="form-check-label" htmlFor="photoConsent">
            I consent to the use of photos on social media.
          </label>
        </div>

        {/* New Parent/Legal Guardian Consent Section */}
        <div className="mb-3">
          <p className="text-justify">
            Parent/Legal Guardian Consent and Waiver of Liability:
            <br />
            <br />
            I understand that Pathways to Employment programs and related
            activities carry inherent risks. These risks can result from the
            activities, my actions or inactions, or those of Pathways, its team,
            volunteers, or others at program sites. I take full responsibility
            for any bodily injury, death, or property damage during my presence
            at Pathways' program sites or participation in its activities,
            regardless of the cause.
            <br />
            <br />
            In consideration of my participation, I waive and release any claims
            against Pathways to Employment, Landowners, Funders, and their
            respective directors, officers, agents, employees, volunteers, and
            affiliates (collectively, the “Released Parties”) for any liability,
            loss, damages, claims, expenses, or attorney fees resulting from my
            presence at a Pathways program site or participation in related
            activities, except for injuries caused by gross negligence or
            willful misconduct of a Released Party. I agree not to sue any of
            the Released Parties for these waived and released claims.
            <br />
            <br />
            If I am a parent/legal guardian of a minor Participant, I
            acknowledge and affirm that I assume these risks and enter into this
            waiver and release on behalf of the Participant.
          </p>

          <div className="form-check mb-3">
            <input
              type="checkbox"
              name="parentGuardianConsent"
              id="parentGuardianConsent"
              className="form-check-input"
              checked={formData.parentGuardianConsent || false}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="parentGuardianConsent">
              Yes, I understand
            </label>
          </div>

          <p className="text-justify">
            By Typing my First and Last Name I acknowledge and represent that I
            have read the foregoing waiver of liability, understand it in the
            language it is written, and sign it voluntarily as my own free act
            and deed as well as the participant minor or conserved waiver as
            their parent/legal guardian.
          </p>
          <input
            type="text"
            name="parentGuardianName"
            placeholder="Type your First and Last Name as Parent/Guardian"
            className="form-control"
            onChange={handleChange}
          />
        </div>

        {/* Emergency Contact Section */}
        <section>
          <h2 className="mb-3">Emergency Contact Information</h2>
          <div className="mb-3">
            <input
              type="text"
              name="emergencyContact1Name"
              placeholder="Contact 1 Name"
              className="form-control"
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <input
              type="tel"
              name="emergencyContact1Phone"
              placeholder="Contact 1 Phone Number"
              className="form-control"
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <input
              type="text"
              name="emergencyContact2Name"
              placeholder="Contact 2 Name"
              className="form-control"
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <input
              type="tel"
              name="emergencyContact2Phone"
              placeholder="Contact 2 Phone Number"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          {/* Disability Section */}
          <div className="mb-3">
            <input
              type="text"
              name="disability"
              placeholder="Disability"
              className="form-control"
              onChange={handleChange}
            />
          </div>

          {/* Allergies Section */}
          <div className="mb-3">
            <input
              type="text"
              name="allergies"
              placeholder="Allergies"
              className="form-control"
              onChange={handleChange}
            />
          </div>

          {/* Behaviors Section */}
          <div className="mb-3">
            <input
              type="text"
              name="behaviors"
              placeholder="Behaviors"
              className="form-control"
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>
              Conserved:
              <br />
              <input
                type="radio"
                name="conserved"
                value="Yes"
                onChange={handleChange}
              />
              Yes
              <br />
              <input
                type="radio"
                name="conserved"
                value="No"
                onChange={handleChange}
              />
              No
            </label>
          </div>

          <div className="mb-3">
            <input
              type="text"
              name="preferredHospital"
              placeholder="Preferred Hospital"
              className="form-control"
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <input
              type="text"
              name="primaryPhysician"
              placeholder="Primary Physician"
              className="form-control"
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <input
              type="text"
              name="serviceCoordinator"
              placeholder="Alta Service Coordinator"
              className="form-control"
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <input
              type="tel"
              name="serviceCoordinatorPhone"
              placeholder="Phone Number"
              className="form-control"
              onChange={handleChange}
            />
          </div>

          <button type="submit" className="btn btn-primary">
            Submit Form
          </button>
        </section>
      </form>
    </div>
  );
};

export default ParticipationFormLayout;
