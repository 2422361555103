import React, { useState } from "react";
import "./Modal.css";
import { useCart } from "../CartContext/CartContext";

const Modal = ({ classInfo, onClose }) => {
  const { addToCart } = useCart();
  const [selectedDates, setSelectedDates] = useState([]);

  if (!classInfo) return null;

  const handleDateSelect = (date) => {
    console.log(`Selected Date: ${date}`);
    if (selectedDates.includes(date)) {
      setSelectedDates(selectedDates.filter((d) => d !== date));
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  const handleAddToCart = () => {
    console.log("Selected Dates:", selectedDates);

    selectedDates.forEach((selectedDate) => {
      const eventIdForDate = classInfo.datesToIds[selectedDate];
      console.log(`Event ID for Date ${selectedDate}:`, eventIdForDate);

      if (!eventIdForDate) {
        console.error("No event ID found for date:", selectedDate);
        return;
      }

      // Retrieve the full event details associated with the event ID from classInfo
      const eventForDate = {
        ...classInfo,
        id: eventIdForDate,
        start_time: selectedDate, // Set the start time to the selected date
        end_time: new Date(
          new Date(selectedDate).getTime() + 3600000
        ).toISOString() // Adding 1 hour to the start time for end time
      };
      console.log("Event Object for Date", selectedDate, ":", eventForDate);

      addToCart(eventForDate);
    });

    onClose();
  };

  // Assuming each class event occurs at a standard time, e.g., 10:00 AM to 12:00 PM local time
  const standardStartTime = "10:00";
  const standardEndTime = "12:00";
  const createGoogleCalendarUrl = () => {
    if (!selectedDates.length) return "#"; // Ensures there are selected dates

    const formatISODate = (dateStr, timeStr) => {
      try {
        // Parse the date string and time string separately
        const dateParts = dateStr.split("T")[0].split("-");
        const timeParts = timeStr.split(":");

        // Extract year, month, and day from date string
        const year = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1; // Months are zero-indexed in JavaScript
        const day = parseInt(dateParts[2], 10);

        // Extract hours and minutes from time string
        const hours = parseInt(timeParts[0], 10);
        const minutes = parseInt(timeParts[1], 10);

        // Create a new Date object with the extracted values
        const date = new Date(year, month, day, hours, minutes);

        // Format the date as ISO 8601 without milliseconds
        return date.toISOString().replace(/-|:|\.\d\d\d/g, "");
      } catch (error) {
        console.error("Error formatting date:", error);
        return null;
      }
    };

    const startTime = formatISODate(selectedDates[0], standardStartTime);
    const endTime = formatISODate(selectedDates[0], standardEndTime);

    if (!startTime || !endTime) {
      console.error("Invalid date values found for:", selectedDates[0]);
      return "#";
    }

    const details = encodeURIComponent(classInfo.description);
    const title = encodeURIComponent(classInfo.title);

    return `https://calendar.google.com/calendar/r/eventedit?text=${title}&dates=${startTime}/${endTime}&details=${details}`;
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-x" onClick={onClose}>
          &times;
        </button>{" "}
        {/* Close icon button */}
        <img
          src={classInfo.image_url}
          alt={classInfo.title}
          className="modal-image"
        />
        <h3>{classInfo.title}</h3>
        <div>
          <h4>Available Dates:</h4>
          <div className="date-selection">
            {classInfo.dates
              ?.filter((date) => new Date(date) > new Date())
              .sort((a, b) => new Date(a) - new Date(b))
              .map((date) => {
                // Split the ISO string into date and time parts
                const [isoDate, isoTime] = date.split("T");
                const [year, month, day] = isoDate.split("-");
                const [hour24, minute] = isoTime.split(":");

                // Convert 24-hour time to 12-hour format
                const hour12 = ((parseInt(hour24) + 11) % 12) + 1; // Convert hour to 12-hour format
                const amPm = parseInt(hour24) >= 12 ? "PM" : "AM";

                // Format the date in a readable format
                const monthNames = [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December"
                ];
                const formattedDate = `${
                  monthNames[parseInt(month) - 1]
                } ${day}, ${year} ${hour12}${amPm}`;

                return (
                  <button
                    key={date}
                    className={`date-button ${
                      selectedDates.includes(date) ? "selected" : ""
                    }`}
                    onClick={() => handleDateSelect(date)}
                  >
                    {formattedDate}
                  </button>
                );
              })}
          </div>
        </div>
        <p>Price: {classInfo.price}</p>
        <p className="modal-detail">{classInfo.description}</p>
        {/* Add to Calendar button */}
        <a
          href={createGoogleCalendarUrl()}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="add-to-calendar">Add to Calendar</button>
        </a>
        <button className="modal-close" onClick={onClose}>
          Close
        </button>
        <button
          className="add-to-cart"
          onClick={handleAddToCart}
          disabled={selectedDates.length === 0}
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
};

export default Modal;
