import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import "./UserProfile.css";
import { useCart } from "../CartContext/CartContext";
import Invoicing from "../Invoicing/Invoicing";

import "bootstrap/dist/css/bootstrap.min.css";
import {
  format,
  isToday,
  isAfter,
  addDays,
  startOfDay,
  isBefore,
  compareAsc,
  isTomorrow,
  isSameDay
} from "date-fns";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    role: "",
    user_id: ""
  });

  const [decodedToken, setDecodedToken] = useState({});
  const [formCompleted, setFormCompleted] = useState(false);
  const [loadingClasses, setLoadingClasses] = useState(true);
  const [userClasses, setUserClasses] = useState([]);
  const [userFullName, setUserFullName] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeDropdownClassId, setActiveDropdownClassId] = useState(null);
  const todayClassesRef = useRef(null);
  const websiteFeaturesRef = useRef(null);
  const staffFeaturesRef = useRef(null);
  const { addToCart } = useCart();
  const [newTodayClasses, setNewTodayClasses] = useState([]);
  const [newTomorrowClasses, setNewTomorrowClasses] = useState([]);
  const navigate = useNavigate();

  const hasClassStarted = (classStartTime) => {
    const now = new Date();
    const startTime = new Date(classStartTime);
    return now > startTime;
  };
  const deduplicateClasses = (classes) => {
    const uniqueClassesMap = new Map();

    classes.forEach((classItem) => {
      const uniqueKey = `${classItem.title}-${classItem.start_time}`;
      if (!uniqueClassesMap.has(uniqueKey)) {
        uniqueClassesMap.set(uniqueKey, classItem);
      }
    });

    return Array.from(uniqueClassesMap.values());
  };

  useEffect(() => {
    const fetchClasses = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("No token found, aborting fetch.");
        return;
      }

      const decoded = jwtDecode(token);
      console.log("Decoded token:", decoded);

      const classesEndpoint = `/classes/class-schedules`; // Endpoint now relative to baseURL
      console.log("Constructed endpoint:", classesEndpoint);

      try {
        console.log(`Attempting to fetch classes`);
        const response = await axiosInstance.get(classesEndpoint);
        console.log("Response received:", response);

        if (response.status === 200 && Array.isArray(response.data)) {
          console.log("Classes fetched successfully:", response.data);
        } else {
          console.error("Unexpected response format or status:", response);
        }
      } catch (error) {
        console.error("Error fetching classes:", error);
      }
    };

    fetchClasses();
  }, []);

  useEffect(() => {
    const fetchAllClasses = async () => {
      try {
        const token = localStorage.getItem("token");
        // Directly use your deployed backend URL for fetching all classes
        const allClassesEndpoint =
          "https://www.ptemployment.org/api/classes/class-schedules";
        console.log("Fetching all classes from:", allClassesEndpoint);
        const responseAllClasses = await axios.get(allClassesEndpoint, {
          headers: { Authorization: `Bearer ${token}` }
        });

        const today = startOfDay(new Date());
        const tomorrow = addDays(today, 1);
        const sevenDaysLater = addDays(today, 7);

        // Filter for new classes by excluding those the user is already signed up for
        let newFilteredTodayClasses = responseAllClasses.data.filter(
          (classItem) =>
            isToday(new Date(classItem.start_time)) &&
            !userClasses.some((userClass) => userClass.id === classItem.id)
        );

        let newFilteredTomorrowClasses = responseAllClasses.data.filter(
          (classItem) =>
            isSameDay(new Date(classItem.start_time), tomorrow) &&
            !userClasses.some((userClass) => userClass.id === classItem.id)
        );

        setNewTodayClasses(newFilteredTodayClasses);
        setNewTomorrowClasses(newFilteredTomorrowClasses);
      } catch (error) {
        console.error("Error fetching all classes:", error);
      }
    };

    fetchAllClasses();
  }, [userClasses]);

  const handleScrollToRef = (ref) => () =>
    ref.current?.scrollIntoView({ behavior: "smooth" });

  const axiosInstance = axios.create({
    baseURL: "https://www.ptemployment.org/api"
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const toggleDropdown = () => setShowDropdown(!showDropdown);
  useEffect(() => {
    const fetchUserClasses = async () => {
      try {
        const token = localStorage.getItem("token");
        console.log("JWT Token:", token);
        // Directly use your deployed backend URL for fetching user classes
        const userClassesEndpoint =
          "https://www.ptemployment.org/api/user-classes";
        console.log("Fetching user classes from:", userClassesEndpoint);
        const response = await axios.get(userClassesEndpoint, {
          headers: { Authorization: `Bearer ${token}` }
        });

        const today = startOfDay(new Date());
        const sevenDaysLater = addDays(today, 7);
        let filteredClasses = response.data.filter(
          (classItem) =>
            isAfter(new Date(classItem.start_time), today) &&
            isBefore(new Date(classItem.start_time), sevenDaysLater)
        );

        // Sort classes by start time
        filteredClasses.sort((a, b) =>
          compareAsc(new Date(a.start_time), new Date(b.start_time))
        );

        setUserClasses(filteredClasses);
      } catch (error) {
        console.error("Error fetching user classes:", error);
      }
    };

    fetchUserClasses();
  }, []);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      setUserDetails({
        name: decoded.username || decoded.name || "",
        email: decoded.email || "",
        role: decoded.role || "User",
        user_id: decoded.user_id || ""
      });

      const checkFormCompletionEndpoint = `https://www.ptemployment.org/api/check-form-completion?user_id=${decoded.user_id}`;

      console.log(
        "Checking form completion from:",
        checkFormCompletionEndpoint
      );
      axios
        .get(checkFormCompletionEndpoint, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => {
          console.log("Form completion response:", response.data);
          setFormCompleted(response.data.isCompleted);
          if (
            response.data.isCompleted &&
            response.data.firstName &&
            response.data.lastName
          ) {
            setUserFullName(
              `${response.data.firstName} ${response.data.lastName}`
            );
          }
        })
        .catch((error) => console.error("Error fetching form status:", error));
      console.log("User role set in UserProfile.js:", decoded.role);
    }
  }, []);

  const handleInvoicingClick = () => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/invoicing", { state: { token } });
    } else {
      console.error("No token found. Cannot navigate to Invoicing page.");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleParticipationFormClick = () => {
    navigate("/participationForm");
  };

  const handleAdminDashboardClick = () => {
    navigate("/adminDashboard");
  };
  const isClassRegisteredForUser = (classItem, userRegisteredClasses) => {
    return userRegisteredClasses.some(
      (registeredClass) => registeredClass.id === classItem.id
    );
  };

  const handleAddToCart = (classItem) => {
    console.log("Adding class to cart:", classItem);
    addToCart(classItem);
  };

  const handleClassSchedulerClick = () => {
    navigate("/classScheduler");
  };

  const todayClasses = userClasses.filter((classItem) =>
    isToday(new Date(classItem.start_time))
  );

  const renderClassItem = (classItem) => (
    <li key={classItem.id} className="class-list-item">
      {classItem.title} - {format(new Date(classItem.start_time), "p")}
      <button
        className="add-to-cart-button"
        onClick={() => addToCart(classItem)}
        style={{
          backgroundColor: "#77dd77",
          color: "white"
        }}
      >
        Add to Cart
      </button>
    </li>
  );

  const formatDateTimeForGoogle = (dateTime) => {
    return new Date(dateTime).toISOString().replace(/-|:|\.\d\d\d/g, "");
  };

  const createGoogleCalendarUrl = (classItem) => {
    const startTime = formatDateTimeForGoogle(classItem.start_time);
    const endTime = formatDateTimeForGoogle(classItem.end_time); // Assume you have an end time
    const details = encodeURIComponent(classItem.description || "Class Event");
    const location = encodeURIComponent("Virtual Event"); // Or classItem.location if available
    const title = encodeURIComponent(classItem.title);

    return `https://calendar.google.com/calendar/r/eventedit?text=${title}&dates=${startTime}/${endTime}&details=${details}&location=${location}`;
  };
  const createOutlookCalendarUrl = (classItem) => {
    const startTime = new Date(classItem.start_time).toISOString();
    const endTime = new Date(classItem.end_time).toISOString();
    const title = encodeURIComponent(classItem.title);
    const details = encodeURIComponent("Class details or description here");
    const location = encodeURIComponent("Class location or 'Online'");

    return `https://outlook.live.com/owa/?path=/calendar/action/compose&startdt=${startTime}&enddt=${endTime}&subject=${title}&body=${details}&location=${location}`;
  };

  const createICalendarData = (classItem) => {
    const startTime = new Date(classItem.start_time)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "")
      .slice(0, -1);
    const endTime = new Date(classItem.end_time)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "")
      .slice(0, -1); // Assume an end_time property
    const title = classItem.title;
    const description = "Class details or description here";
    const location = "Class location or 'Online'";

    return `BEGIN:VCALENDAR
  VERSION:2.0
  BEGIN:VEVENT
  SUMMARY:${title}
  DTSTART:${startTime}
  DTEND:${endTime}
  DESCRIPTION:${description}
  LOCATION:${location}
  END:VEVENT
  END:VCALENDAR`;
  };

  const handleAddToCalendarClick = (classId) => {
    setActiveDropdownClassId((prevId) => (prevId === classId ? null : classId));
  };

  const tomorrow = addDays(new Date(), 1);
  const tomorrowClasses = userClasses.filter((classItem) =>
    isSameDay(new Date(classItem.start_time), tomorrow)
  );

  return (
    <div className="user-profile container">
      <div className="quick-links">
        <span>Quick Links:</span>
        <button onClick={handleScrollToRef(todayClassesRef)}>
          Today's Classes
        </button>
        <button onClick={handleScrollToRef(websiteFeaturesRef)}>
          Website Features
        </button>
        {userDetails.role === "Administrator" ||
        userDetails.role === "Staff" ? (
          <button onClick={handleScrollToRef(staffFeaturesRef)}>
            Staff Features
          </button>
        ) : null}
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <h1>
        {userFullName ? (
          <>
            <span className="role-highlight">{userFullName}'s</span> Profile
          </>
        ) : (
          "User Profile"
        )}
      </h1>
      <div className="user-details mb-3">
        <p>Username: {userDetails.name}</p>
        <p>Email: {userDetails.email}</p>
        {userDetails.role !== "User" && (
          <p className="role-highlight">Role: {userDetails.role}</p>
        )}
      </div>

      {/* Interest Promotion Section */}
      <div className="interest-promotion">
        <p className="interest-promotion-heading">
          Interested in joining a class today or tomorrow? Explore available
          options!
        </p>
        <div className="class-options">
          {/* Today's Classes Section */}
          <div className="today-classes">
            <h3>Today's Classes:</h3>
            {newTodayClasses.length > 0 ? (
              <ul>
                {newTodayClasses
                  .filter((classItem) => !hasClassStarted(classItem.start_time))
                  .map((classItem) => renderClassItem(classItem))}
              </ul>
            ) : (
              <p>
                All available classes for today have been signed up for or are
                no longer available. Please check back for future classes or
                explore other days for more opportunities.
              </p>
            )}
          </div>

          {/* Tomorrow's Classes Section */}
          <div className="tomorrow-classes">
            <h3>Tomorrow's Classes:</h3>
            {newTomorrowClasses.length > 0 ? (
              <ul>
                {newTomorrowClasses.map((classItem) =>
                  renderClassItem(classItem)
                )}
              </ul>
            ) : (
              <p>
                All available classes for tomorrow have been signed up for or no
                longer available. Keep an eye on upcoming days for new classes
                to join.
              </p>
            )}
          </div>
        </div>
      </div>

      {/* Additional Section for Class Calendars and Descriptions */}
      <div className="alternative-options-section">
        <h3>Or:</h3>
        <p className="text-center">Explore more ways to find classes:</p>
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary mr-2"
            onClick={() => navigate("/monthlySchedule")}
            style={{ backgroundColor: "#007bff", color: "white" }}
          >
            Check Our Class Calendar
          </button>
          <button
            className="btn btn-info"
            onClick={() => navigate("/services")}
            style={{ backgroundColor: "#17a2b8", color: "white" }}
          >
            Class Descriptions
          </button>
        </div>
        <p className="text-center mt-3">
          Our interactive and colorful class calendar helps you easily find and
          join classes. The class description page provides detailed information
          about the classes.
        </p>
      </div>

      <div className="disclaimer">
        <p>
          <strong>Disclaimer:</strong> You will not be able to sign up for
          classes unless the participation form is completed.
        </p>
      </div>

      <div className="form-status-section mb-3">
        <h2>Form Status</h2>

        <div
          className={`form-status ${
            formCompleted ? "completed" : "not-completed"
          }`}
        >
          {formCompleted ? "✔️ Form Completed" : "❌ Form Not Completed"}
          <button
            className="btn btn-primary mb-3"
            onClick={handleParticipationFormClick}
          >
            Fill Participation Form
          </button>
        </div>
      </div>
      <div className="classes-today">
        <div ref={todayClassesRef} className="classes-today">
          <h2>
            {userFullName ? (
              <span className="role-highlight">{userFullName}'s</span>
            ) : (
              "User's"
            )}{" "}
            Classes for <span style={{ color: "#28a745" }}>Today</span>
          </h2>
        </div>

        {todayClasses.length > 0 ? (
          todayClasses.map((classItem, index) => (
            <div key={index} className="class-item">
              <p className="class-title">
                {classItem.title} -{" "}
                <span className="class-time">
                  {format(
                    new Date(classItem.start_time),
                    "MMMM do, yyyy hh:mm a"
                  )}
                  <button
                    className="add-to-calendar-button"
                    onClick={() => handleAddToCalendarClick(classItem.id)}
                  >
                    Add to Calendar
                  </button>
                  {activeDropdownClassId === classItem.id && (
                    <div className="calendar-options-dropdown">
                      <a
                        href={createGoogleCalendarUrl(classItem)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Calendar
                      </a>
                      <a
                        href={createOutlookCalendarUrl(classItem)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Outlook Calendar
                      </a>
                      <a
                        href={`data:text/calendar;charset=utf8,${encodeURIComponent(
                          createICalendarData(classItem)
                        )}`}
                        download={`${classItem.title}.ics`}
                      >
                        iCalendar
                      </a>
                    </div>
                  )}
                </span>
              </p>
            </div>
          ))
        ) : (
          <p>No classes for today.</p>
        )}
      </div>

      <div className="classes-next-7-days">
        <h2>
          {userFullName ? (
            <span className="role-highlight">{userFullName}'s</span>
          ) : (
            "User's"
          )}{" "}
          Classes Coming Up in the{" "}
          <span style={{ color: "#28a745" }}>Next 7 Days</span>
        </h2>

        {userClasses.length > 0 ? (
          userClasses.map((classItem, index) => (
            <div key={index} className="class-item">
              <p className="class-title">
                {classItem.title} -{" "}
                <span className="class-time">
                  {format(
                    new Date(classItem.start_time),
                    "MMMM do, yyyy hh:mm a"
                  )}
                  <button
                    className="add-to-calendar-button"
                    onClick={() => handleAddToCalendarClick(classItem.id)}
                  >
                    Add to Calendar
                  </button>
                  {activeDropdownClassId === classItem.id && (
                    <div className="calendar-options-dropdown">
                      <a
                        href={createGoogleCalendarUrl(classItem)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Calendar
                      </a>
                      <a
                        href={createOutlookCalendarUrl(classItem)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Outlook Calendar
                      </a>
                      <a
                        href={`data:text/calendar;charset=utf8,${encodeURIComponent(
                          createICalendarData(classItem)
                        )}`}
                        download={`${classItem.title}.ics`}
                      >
                        iCalendar
                      </a>
                    </div>
                  )}
                </span>
              </p>
            </div>
          ))
        ) : (
          <p>No classes found for the next 7 days.</p>
        )}
      </div>

      <div className="row">
        <div className="col-md-6">
          <div ref={websiteFeaturesRef} className="website-features">
            <h3>Website Features</h3>
            <button
              className="btn btn-info d-block mb-2"
              onClick={() => navigate("/monthlySchedule")}
            >
              Monthly Calendar
            </button>
          </div>

          <button
            className="btn btn-info d-block mb-2"
            onClick={() => navigate("/services")}
          >
            Class Description
          </button>
          <button
            className="btn btn-info d-block mb-2"
            onClick={() => navigate("/myClasses")}
          >
            My Classes
          </button>
        </div>
        <div className="col-md-6">
          <h3>Website Information</h3>
          <button
            className="btn btn-success d-block mb-2"
            onClick={() => navigate("/aboutUs")}
          >
            About Us
          </button>

          <button
            className="btn btn-success d-block mb-2"
            onClick={() => navigate("/contact")}
          >
            Contact Us
          </button>

          <button
            className="btn btn-warning d-block mb-5"
            onClick={() => navigate("/policies")}
          >
            Policies
          </button>
        </div>
      </div>

      {(userDetails.role === "Administrator" ||
        userDetails.role === "Staff") && (
        <div className="staff-features-section mb-5">
          <h3>Staff Features</h3>
          {["Administrator", "Staff"].includes(userDetails.role) && (
            <div ref={staffFeaturesRef} className="staff-features-section">
              <button
                className="btn btn-secondary d-block"
                onClick={() => navigate("/classScheduler")}
              >
                Class Scheduler
              </button>
            </div>
          )}
          <button
            className="btn btn-secondary d-block"
            onClick={() => navigate("/classLists")}
          >
            Class Lists
          </button>
        </div>
      )}

      {userDetails.role === "Administrator" && (
        <div className="admin-features-section mb-3 admin-dashboard-spacing">
          <h3>Administration Features</h3>
          <button
            className="btn btn-dark d-block mb-2"
            onClick={handleAdminDashboardClick}
          >
            Admin Dashboard
          </button>
          <button
            className="btn btn-dark d-block mb-2"
            onClick={handleInvoicingClick}
          >
            Invoicing
          </button>
        </div>
      )}

      <button className="btn btn-danger mb" onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

export default UserProfile;
