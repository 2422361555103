import React, { useState, useEffect, useCallback } from "react";
import { useCart } from "../CartContext/CartContext";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { jwtDecode } from "jwt-decode";
import "./CartPage.css";

const CartPage = () => {
  const { cartItems, removeFromCart, clearCart } = useCart();
  const { isAuthenticated, userRole, getToken } = useAuth();
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isSignInMessageDisplayed, setIsSignInMessageDisplayed] = useState(
    !isAuthenticated
  );

  const initialOptions = {
    "client-id":
      "AZMzuQYJ6QBFjl9sx-IdI_2OaOHd7AhJuhJ1iTtFLdjqOBNrc5eg23vcMvvx7WOiizE3RHt610W7-OGH",
    currency: "USD",
    intent: "capture"
  };

  const calculateTotal = useCallback(() => {
    return cartItems.reduce(
      (total, item) => total + parseFloat(item.price || 0),
      0
    );
  }, [cartItems]);

  const formatCartItem = (item) => {
    const formatDateTimeFromString = (dateTimeString) => {
      if (!dateTimeString) return "No Date Provided";

      const [datePart, timePart] = dateTimeString.split("T");
      const [year, month, day] = datePart.split("-").map(Number);
      const [hours, minutes] = timePart.split(":").map(Number);

      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      const formattedMonth = monthNames[month - 1];
      const amPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = ((hours + 11) % 12) + 1;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      return `${formattedMonth} ${day}, ${year} ${formattedHours}:${formattedMinutes} ${amPm}`;
    };

    const formattedDate = formatDateTimeFromString(item.start_time);
    const title = item.title || item.name || "No Title Provided";
    const price = item.price ? parseFloat(item.price) : 0;
    const imageUrl = item.image_url || "";

    return {
      title,
      formattedDate,
      price,
      imageUrl
    };
  };

  //////////////////////////////////////////////////////////////////

  const handleJoinClassClick = async () => {
    if (!token) {
      console.error("No token available.");
      return;
    }

    try {
      const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3000";

      const responses = await Promise.all(
        cartItems.map((item) => {
          return axios.post(
            `${baseURL}/api/event-registrations`,
            {
              user_id: userId,
              event_id: item.id
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );
        })
      );

      const allSucceeded = responses.every(
        (response) => response.status === 200
      );
      if (allSucceeded) {
        if (userRole === "Self Determination Program (SDP)") {
          const invoiceResponse = await axios.post(
            `${baseURL}/backend/quickbooks/create-invoice`,
            {
              amount: calculateTotal(),
              itemId: "12345", // Replace with your actual item ID
              itemName: "Class Registration",
              customerId: userId,
              user_id: userId
            },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }
          );

          if (invoiceResponse.status === 200) {
            console.log("Invoice created successfully:", invoiceResponse.data);
          } else {
            throw new Error("Invoice creation failed.");
          }
        }

        localStorage.setItem("registeredClasses", JSON.stringify(cartItems));
        clearCart();
        navigate("/registration-success");
      } else {
        throw new Error("Some registrations failed.");
      }
    } catch (error) {
      console.error("Registration error:", error);
      if (error.response) {
        console.error("Response data:", error.response.data);
        console.error("Response status:", error.response.status);
        console.error("Response headers:", error.response.headers);
      } else if (error.request) {
        console.error("Request data:", error.request);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  //////////////////////////////////////////////////////

  useEffect(() => {
    setIsSignInMessageDisplayed(!isAuthenticated);
    const retrievedToken = getToken();
    setToken(retrievedToken);

    if (retrievedToken) {
      const decodedToken = jwtDecode(retrievedToken);
      setUserId(decodedToken.user_id);
      console.log("Decoded Token:", decodedToken);
      console.log("User ID set:", decodedToken.user_id);
    }

    console.log("Current user role:", userRole); // Debugging log
  }, [isAuthenticated, getToken, userRole]);

  return (
    <PayPalScriptProvider options={initialOptions}>
      <div className="cart-page container">
        <h1>Your Cart</h1>
        {!isAuthenticated && isSignInMessageDisplayed && (
          <div className="mb-4 text-center">
            <p className="form-incomplete-message">
              Please sign in to proceed to checkout.
              <br />
              <span
                className="sign-in-link"
                onClick={() => navigate("/login")}
                style={{
                  color: "#007bff",
                  cursor: "pointer",
                  textDecoration: "underline"
                }}
              >
                Click here to sign in.
              </span>
            </p>
          </div>
        )}
        {isAuthenticated && (
          <div>
            <ul className="cart-list">
              {cartItems.map((item, index) => {
                const { title, formattedDate, price, imageUrl } =
                  formatCartItem(item);
                return (
                  <li key={index} className="cart-item">
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt={title}
                        className="cart-item-image"
                      />
                    )}
                    <div className="cart-item-details">
                      <div className="cart-item-name">{title}</div>
                      <div className="cart-item-date">{formattedDate}</div>
                      <div className="cart-item-price">
                        {price > 0 ? `$${price.toFixed(2)}` : "Free"}
                      </div>
                    </div>
                    <div className="cart-item-actions">
                      <button
                        className="btn btn-danger remove-btn"
                        onClick={() => removeFromCart(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </li>
                );
              })}
            </ul>
            {userRole === "Self Determination Program (SDP)" ? (
              <div className="text-center mt-4">
                <button
                  className="btn btn-success"
                  onClick={handleJoinClassClick}
                >
                  Join Class
                </button>
              </div>
            ) : (
              cartItems.some((item) => item.price > 0) && (
                <div className="text-center mt-4">
                  <h3>Total: ${calculateTotal().toFixed(2)}</h3>
                  <div
                    className="buttons-container"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      padding: "20px 0"
                    }}
                  >
                    <div style={{ width: "50%" }}>
                      <PayPalButtons
                        style={{
                          layout: "horizontal",
                          height: 50
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-2 mb-3">
                    <hr style={{ width: "100%", margin: "0" }} />
                    <span style={{ padding: "5%" }}>or</span>
                    <hr style={{ width: "100%", margin: "0" }} />
                  </div>
                  <button
                    className="stripe-button"
                    onClick={() =>
                      navigate("/stripeCheckout", {
                        state: {
                          userId,
                          eventId: cartItems.map((item) => item.id)
                        }
                      })
                    }
                  >
                    Checkout with{" "}
                    <span className="stripe-word">Credit Card</span>
                  </button>
                </div>
              )
            )}
          </div>
        )}
        <div className="mt-4 text-center"></div>
      </div>
    </PayPalScriptProvider>
  );
};

export default CartPage;
