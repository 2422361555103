import React from "react";
import "./DynamicSection.css";

const DynamicSection = () => {
  // Placeholder content, replace with dynamic content from admin dashboard
  const dynamicContent = `Pathways to Employment has placed multiple clients in meaningful work positions throughout Northern California. Our community members benefit from the direct interaction they get with our clients and start to see our young adults for more than just being disabled. Our clients demonstrate the following skills: improved confidence working with customers, improved verbal and social skills, increased independence, improved fine motor skills, improved motor planning, better math skills, increased community participation, sense of purpose that comes from having a job and learning new skills.`;

  return (
    <section className="dynamic-section">
      <div className="headline-container">
        <div className="headline-lines"></div>
        <h2>Success Stories</h2>
        <div className="headline-lines"></div>
      </div>
      <p>{dynamicContent}</p>
    </section>
  );
};

export default DynamicSection;
