import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [roleChanges, setRoleChanges] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [showNewUsers, setShowNewUsers] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);
  const [editingUserDetails, setEditingUserDetails] = useState({});
  const [updateMessage, setUpdateMessage] = useState("");
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          `https://www.ptemployment.org/api/users-with-forms`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        );

        if (!response.ok) {
          const errorText = await response.text(); // Fetching text to see what's actually coming back
          console.error(
            "Failed to fetch users, server responded with:",
            errorText
          );
          throw new Error("Failed to fetch users");
        }
        const data = await response.json();
        console.log("Fetched users:", data);
        setUsers(data);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, [showNewUsers]);

  const handleRoleSelect = (userId, newRole) => {
    setRoleChanges((prev) => ({ ...prev, [userId]: newRole }));
  };

  const handleDeleteUser = async (userId) => {
    const userToDelete = users.find((user) => user.user_id === userId);
    const userName = userToDelete
      ? `${userToDelete.first_name} ${userToDelete.last_name || ""}`.trim()
      : "this user";

    const isConfirmed = window.confirm(
      `Are you sure you want to delete ${userName}?`
    );

    if (!isConfirmed) {
      return; // If user cancels, do nothing
    }

    try {
      const response = await fetch(
        `https://www.ptemployment.org/api/auth/delete-user/${userId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to delete user.");
      }

      setUsers(users.filter((user) => user.user_id !== userId));
      setDeleteErrorMessage("");
    } catch (error) {
      console.error("Error deleting user:", error);
      setDeleteErrorMessage(error.message);
    }
  };

  const handleEditUser = (userId) => {
    const userToEdit = users.find((user) => user.user_id === userId);
    if (userToEdit) {
      setEditingUserId(userId);
      setEditingUserDetails({
        first_name: userToEdit.first_name,
        last_name: userToEdit.last_name,
        email: userToEdit.email
      });
    }
  };

  const handleUserDetailsChange = (e) => {
    const { name, value } = e.target;
    setEditingUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value
    }));
  };

  const handleSaveEditedUser = async () => {
    try {
      const response = await fetch(
        `https://www.ptemployment.org/api/auth/update-user-details/${editingUserId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          body: JSON.stringify(editingUserDetails)
        }
      );

      if (!response.ok) throw new Error("Failed to update user details.");

      const updatedUsers = users.map((user) =>
        user.user_id === editingUserId
          ? { ...user, ...editingUserDetails }
          : user
      );
      setUsers(updatedUsers);
      setEditingUserId(null);
    } catch (error) {
      console.error("Error saving edited user:", error);
    }
  };

  const handleSubmit = async (userId) => {
    const newRole = roleChanges[userId];
    if (!newRole) return;

    try {
      const response = await fetch(
        `https://www.ptemployment.org/api/auth/update-role`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          },
          body: JSON.stringify({ userId, newRole })
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update role");
      }

      // Update local state after successful role update
      const updatedUsers = users.map((user) =>
        user.user_id === userId ? { ...user, role: newRole } : user
      );
      setUsers(updatedUsers);
      // Clear the role change for the updated user
      setRoleChanges((prev) => ({ ...prev, [userId]: undefined }));

      // Set and show the confirmation message
      setUpdateMessage(`Role updated to ${newRole} successfully!`);
      setShowUpdateMessage(true);

      // Hide the message after 5 seconds
      setTimeout(() => setShowUpdateMessage(false), 5000);
    } catch (error) {
      console.error("Error updating role:", error);
    }
  };

  const toggleNewUsers = () => {
    setShowNewUsers(!showNewUsers);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const now = new Date();
  const filteredUsers = users.filter((user) => {
    const creationDate = new Date(user.created_at);
    const withinLast7Days = showNewUsers
      ? now - creationDate <= 7 * 24 * 60 * 60 * 1000
      : true;
    const matchesSearch =
      (user.username?.toLowerCase() || "").includes(searchQuery) ||
      (user.email?.toLowerCase() || "").includes(searchQuery) ||
      (user.first_name?.toLowerCase() || "").includes(searchQuery) ||
      (user.last_name?.toLowerCase() || "").includes(searchQuery) ||
      (user.role?.toLowerCase() || "").includes(searchQuery);

    return withinLast7Days && matchesSearch;
  });

  return (
    <div className="admin-dashboard-container">
      {showUpdateMessage && (
        <div className="update-message">{updateMessage}</div>
      )}

      <div className="admin-dashboard-header mb-5 text-center">
        <h1 className="admin-dashboard-title">Admin Dashboard</h1>
        <button onClick={toggleNewUsers} className="btn btn-info ms-2 mb-2">
          {showNewUsers ? "Show All Users" : "New Users from Last 7 Days"}
        </button>

        <input
          type="text"
          placeholder="Search users..."
          className="form-control ms-2"
          style={{ maxWidth: "300px", display: "inline-block" }}
          onChange={handleSearchChange}
        />
      </div>
      {filteredUsers.map((user) => (
        <div key={user.user_id} className="user-item text-center">
          {editingUserId === user.user_id ? (
            <div className="d-flex flex-column align-items-center">
              <input
                type="text"
                name="first_name"
                value={editingUserDetails.first_name}
                onChange={handleUserDetailsChange}
                className="form-control mb-2"
                placeholder="First Name"
              />
              <input
                type="text"
                name="last_name"
                value={editingUserDetails.last_name}
                onChange={handleUserDetailsChange}
                className="form-control mb-2"
                placeholder="Last Name"
              />
              <input
                type="email"
                name="email"
                value={editingUserDetails.email}
                onChange={handleUserDetailsChange}
                className="form-control mb-2"
                placeholder="Email"
              />
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-success me-2"
                  onClick={handleSaveEditedUser}
                >
                  Save
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => setEditingUserId(null)}
                >
                  Cancel
                </button>
              </div>
            </div>
          ) : (
            <div>
              <span className="user-info">
                ID: {user.user_id} - {user.first_name} {user.last_name} -{" "}
                {user.email} - Role: {user.role}
              </span>
              <div className="user-actions d-flex justify-content-center mt-2">
                <select
                  className="form-select me-2"
                  value={roleChanges[user.user_id] || user.role}
                  onChange={(e) =>
                    handleRoleSelect(user.user_id, e.target.value)
                  }
                >
                  <option value="User">User</option>
                  <option value="Staff">Staff</option>
                  <option value="Administrator">Administrator</option>
                  <option value="Self Determination Program (SDP)">
                    Self Determination Program (SDP)
                  </option>
                </select>
                <button
                  className="btn btn-primary me-2"
                  onClick={() => handleSubmit(user.user_id)}
                >
                  Update Role
                </button>
                <button
                  className="btn btn-warning me-2"
                  onClick={() => handleEditUser(user.user_id)}
                >
                  Edit
                </button>
                <button
                  className="btn btn-danger"
                  onClick={() => handleDeleteUser(user.user_id)}
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>
      ))}
      <div className="back-to-profile-bottom mt-4 text-center">
        <button
          onClick={() => navigate("/userProfile")}
          className="btn btn-primary mb-4"
        >
          Back to Profile
        </button>
      </div>
    </div>
  );
};

export default AdminDashboard;
