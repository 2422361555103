import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import "./UserList.css";
import "./UserListPrint.css";
import "bootstrap/dist/css/bootstrap.min.css";

const UserList = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [users, setUsers] = useState([]);

  const eventDetails = location.state?.eventDetails || null;

  useEffect(() => {
    if (!eventDetails) {
      console.error("No event details provided");
      navigate("/classLists");
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, authorization required");
      navigate("/classLists");
      return;
    }

    const authHeaders = { headers: { Authorization: `Bearer ${token}` } };
    const usersForEventEndpoint = `https://www.ptemployment.org/api/events/${eventId}/registrations`;
    const participationFormsEndpoint = `https://www.ptemployment.org/api/participation-waivers`;

    axios
      .get(participationFormsEndpoint, authHeaders)
      .then((participationResponse) => {
        const participationFormsData = participationResponse.data;
        return axios
          .get(usersForEventEndpoint, authHeaders)
          .then((usersResponse) => {
            const enrichedUsers = usersResponse.data.map((user) => {
              const userForm = participationFormsData.find(
                (form) => form.user_id === user.user_id
              );
              return {
                ...user,
                firstName: userForm?.first_name || user.first_name || "N/A",
                lastName: userForm?.last_name || user.last_name || "N/A",
                age: userForm?.age || "N/A"
              };
            });
            setUsers(enrichedUsers);
          });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  }, [eventId, eventDetails, navigate]);

  const handlePrint = () => {
    window.print();
  };

  return (
    <div className="user-list-container">
      <div className="mb-3 print-hide">
        <button
          onClick={() => navigate("/userProfile")}
          className="btn btn-primary me-2 mb-2"
        >
          Back to Profile
        </button>
        <button
          onClick={() => navigate("/classLists")}
          className="btn btn-info me-2 mb-2"
        >
          Back to Class Lists
        </button>
        <button onClick={handlePrint} className="btn btn-warning">
          Print
        </button>
      </div>
      <div className="card">
        <div className="card-header">
          <h2 className="h4 mb-0">
            Users Registered for "{eventDetails?.title || "Event"}"
          </h2>
        </div>
        <div className="card-body">
          <p className="card-text mb-2">
            <strong>Date:</strong>{" "}
            {eventDetails?.start_time
              ? new Date(eventDetails.start_time).toLocaleDateString()
              : "Date not available"}
            <br />
            <strong>Time:</strong>{" "}
            {eventDetails?.start_time
              ? new Date(eventDetails.start_time).toLocaleTimeString()
              : "Time not available"}
          </p>
          <ul className="list-group list-group-flush">
            {users.map((user, index) => (
              <li key={index} className="list-group-item">
                {user.firstName} {user.lastName} - Role: {user.role} - Username:{" "}
                {user.username} - Email: {user.email}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserList;
