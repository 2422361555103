import React, { useState } from "react";
import ParticipationFormLayout from "./ParticipationFormLayout";
import { jwtDecode } from "jwt-decode";
import "./ParticipationForm.css";
import { useNavigate } from "react-router-dom";

const ParticipationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    age: "",
    dateOfBirth: "",
    email: "",
    phone: "",
    photoConsent: false,
    waiverConsent: false,
    parentGuardianName: "",
    emergencyContact1Name: "",
    emergencyContact1Phone: "",
    emergencyContact2Name: "",
    emergencyContact2Phone: "",
    disability: "",
    allergies: "",
    behaviors: "",
    conserved: false,
    preferredHospital: "",
    primaryPhysician: "",
    serviceCoordinator: "",
    serviceCoordinatorPhone: ""
  });

  const formatPhoneNumber = (phoneNumber) => {
    let cleaned = ("" + phoneNumber).replace(/\D/g, "");
    if (cleaned.startsWith("1")) {
      cleaned = cleaned.substring(1);
    }
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `${match[1]}-${match[2]}-${match[3]}` : null;
  };

  const handleChange = (event) => {
    const { name, type, value, checked } = event.target;
    let formattedValue = value;
    if (
      name === "phone" ||
      name === "emergencyContact1Phone" ||
      name === "emergencyContact2Phone"
    ) {
      formattedValue = formatPhoneNumber(value) || value;
    } else {
      formattedValue = type === "checkbox" ? checked : value;
    }
    setFormData((prevData) => ({ ...prevData, [name]: formattedValue }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login");
      navigate("login");
      return;
    }
    const decoded = jwtDecode(token); // Use named import
    const user_id = decoded.user_id;
    const submissionData = { ...formData, user_id };

    const saveParticipationFormEndpoint =
      "https://www.ptemployment.org/api/auth/saveParticipationForm";

    try {
      const response = await fetch(saveParticipationFormEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(submissionData)
      });

      if (response.ok) {
        console.log("Form submitted successfully.");
        alert("Form submitted successfully.");
        navigate("/userProfile"); // Ensure the correct path to navigate
      } else {
        console.error("Failed to submit the form.");
        alert("Failed to submit the form, please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form, please try again.");
    }
  };

  return (
    <div className="form-container">
      <ParticipationFormLayout
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default ParticipationForm;
