import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useCart } from "../CartContext/CartContext";
import "./EventDetailsPopup.css";

const EventDetailsPopup = ({
  event,
  onEdit,
  onDelete,
  onClose,
  displayOnly = false
}) => {
  const [editableEvent, setEditableEvent] = useState({
    title: event.title,
    start_time: event.start_time,
    end_time: event.end_time,
    description: event.description,
    price: event.price,
    description_url: event.description_url,
    event_color: event.event_color
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const isPastEvent = new Date() > new Date(editableEvent.start_time);

  const { addToCart } = useCart();

  const handleAddToCart = () => {
    console.log("Event object:", event);

    const itemToAdd = {
      id: event.id,
      name: event.title,
      price: event.price,
      start_time: event.start_time,
      end_time: event.end_time,
      image_url: event.image_url
    };

    console.log("Adding to cart:", itemToAdd);
    addToCart(itemToAdd);
    onClose();
  };

  const formatDateTimeForDisplay = (isoString) => {
    const date = new Date(isoString);
    date.setHours(date.getHours() + 4); // Adjust the time by adding 4 hours
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true
    };
    return date.toLocaleString("en-US", options);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setEditableEvent({ ...editableEvent, [name]: value });

    if (name === "start_time") {
      const [datePart, timePart] = value.split("T");
      const [year, month, day] = datePart
        .split("-")
        .map((num) => parseInt(num, 10));
      const [hours, minutes] = timePart
        .split(":")
        .map((num) => parseInt(num, 10));

      const startTimeUtc = new Date(
        Date.UTC(year, month - 1, day, hours, minutes)
      );

      const endTimeUtc = new Date(startTimeUtc.getTime() + 30 * 60000);

      const endTimeString = endTimeUtc.toISOString().substring(0, 16);

      setEditableEvent((prevState) => ({
        ...prevState,
        end_time: endTimeString
      }));
    }
  };

  const handleSave = () => {
    const updatedEvent = {
      ...event,
      ...editableEvent
    };

    console.log("Saving event with color:", updatedEvent.event_color);
    onEdit(updatedEvent);
    setIsEditMode(false);
  };

  const handleEditEvent = async (updatedEvent) => {
    try {
      console.log("Sending update request for event:", updatedEvent);

      const updateEndpoint =
        "https://www.ptemployment.org/api/class-schedules/" + updatedEvent.id;

      const response = await axios.put(updateEndpoint, updatedEvent, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      });

      console.log("Update response:", response);
    } catch (error) {
      console.error("Error updating event:", error);
    }
  };

  const createGoogleCalendarUrl = () => {
    const startTime = new Date(editableEvent.start_time)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "");
    const endTime = new Date(editableEvent.end_time)
      .toISOString()
      .replace(/-|:|\.\d\d\d/g, "");
    const details = encodeURIComponent(editableEvent.description);
    const title = encodeURIComponent(editableEvent.title);

    return `https://calendar.google.com/calendar/r/eventedit?text=${title}&dates=${startTime}/${endTime}&details=${details}`;
  };

  return (
    <div className="ev-detail-overlay">
      <div className="ev-detail-dialog event-modal-dialog modal-lg">
        <div className="ev-detail-content event-modal-content">
          <div className="ev-detail-header">
            <h5 className="modal-title">
              {isEditMode ? "Edit Event" : "Event Details"}
            </h5>
            <button className="modal-close-x" onClick={onClose}>
              &times;
            </button>{" "}
          </div>
          <div className="modal-body">
            {event.image_url && (
              <img
                src={event.image_url}
                alt={event.title}
                className="event-image"
              />
            )}
            {/* Editable form for event details */}
            {isEditMode ? (
              <>
                <input
                  type="text"
                  className="form-control mb-3"
                  name="title"
                  value={editableEvent.title}
                  onChange={handleInputChange}
                />
                <input
                  type="datetime-local"
                  className="form-control mb-3"
                  name="start_time"
                  value={editableEvent.start_time}
                  onChange={handleInputChange}
                />
                <input
                  type="datetime-local"
                  className="form-control mb-3"
                  name="end_time"
                  value={editableEvent.end_time}
                  onChange={handleInputChange}
                />
                <textarea
                  className="form-control mb-3"
                  name="description"
                  value={editableEvent.description}
                  onChange={handleInputChange}
                />
                <input
                  type="number"
                  className="form-control mb-3"
                  name="price"
                  value={editableEvent.price}
                  onChange={handleInputChange}
                />
                <input
                  type="text"
                  className="form-control mb-3"
                  name="description_url"
                  value={editableEvent.description_url}
                  onChange={handleInputChange}
                />
              </>
            ) : (
              <>
                <h3>{event.title}</h3>
                <p>Start Time: {formatDateTimeForDisplay(event.start_time)}</p>
                <p>End Time: {formatDateTimeForDisplay(event.end_time)}</p>
                <p>Description: {event.description}</p>
                <p>Price: ${event.price}</p>
                {event.description_url && (
                  <a
                    href={event.description_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    More Info
                  </a>
                )}
              </>
            )}
          </div>
          <div className="modal-footer">
            {!displayOnly && (
              <>
                {isEditMode ? (
                  <button className="btn btn-primary" onClick={handleSave}>
                    Save
                  </button>
                ) : (
                  <>
                    <button
                      className="btn btn-secondary"
                      onClick={() => setIsEditMode(true)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        onDelete(event.id);
                        onClose();
                      }}
                    >
                      Delete
                    </button>
                  </>
                )}
              </>
            )}
            {!isPastEvent && (
              <>
                <a
                  href={createGoogleCalendarUrl()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button className="btn btn-danger">Add to Calendar</button>
                </a>
                <button className="btn btn-primary" onClick={handleAddToCart}>
                  Add to Cart
                </button>
              </>
            )}
            <button className="btn btn-secondary" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetailsPopup;
