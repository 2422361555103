import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import "./RegistrationSuccess.css";

const RegistrationSuccess = () => {
  const { user } = useAuth();
  const [registeredClasses, setRegisteredClasses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const classes = JSON.parse(localStorage.getItem("registeredClasses")) || [];
    setRegisteredClasses(classes);
  }, []);

  return (
    <div className="container text-center mt-5" style={{ marginTop: "100px" }}>
      <h1 className="success-title">Success!</h1>
      <p>{user.name}, you have successfully joined the following classes:</p>
      <ul className="list-group mb-4">
        {registeredClasses.map((item, index) => (
          <li key={index} className="list-group-item">
            {item.title}
          </li>
        ))}
      </ul>
      <div className="button-container">
        <button
          className="btn btn-primary me-2"
          onClick={() => navigate("/userProfile")}
        >
          Back to Profile
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => navigate("/monthlySchedule")}
        >
          Back to Monthly Calendar
        </button>
      </div>
    </div>
  );
};

export default RegistrationSuccess;
