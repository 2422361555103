import React from "react";
import { GoogleLogin } from "@react-oauth/google";

const clientId =
  "711154044658-25o8imctgqmdri9drjqktehqairc2sa6.apps.googleusercontent.com";

function GoogleAuthTest() {
  const handleSuccess = (credentialResponse) => {
    console.log("Authentication successful, user:", credentialResponse);
    alert("Logged in successfully!");
  };

  const handleError = () => {
    console.error("Login Failed");
    alert("Login failed!");
  };

  return (
    <div>
      <h1>Google Auth Test</h1>
      <GoogleLogin
        clientId={clientId}
        onSuccess={handleSuccess}
        onError={handleError}
      />
    </div>
  );
}

export default GoogleAuthTest;
