import React from "react";
import { useNavigate } from "react-router-dom";
import "./Contact.css";

const Contact = () => {
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem("token");

  return (
    <div className="contact-container">
      {isLoggedIn && (
        <div className="back-to-profile-top mb-4">
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        </div>
      )}

      <h1>Contact Us!</h1>
      <p>9813 Fair Oaks Blvd, Suite A</p>
      <p>Fair Oaks, CA 95628</p>
      <p>916-784-9809</p>
      <p>
        <a href="mailto:info@pathwaystoemploymentgb.org">
          info@pathwaystoemploymentgb.org
        </a>
      </p>

      <div className="social-media-links">
        <a
          href="https://www.facebook.com/pathwaysempcorp/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Facebook
        </a>
        <a
          href="https://www.instagram.com/pathwaystoemploymentgb/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
      </div>

      <div className="contact-form">
        <form>
          <label htmlFor="firstName">First Name*</label>
          <input type="text" id="firstName" name="firstName" required />

          <label htmlFor="lastName">Last Name*</label>
          <input type="text" id="lastName" name="lastName" required />

          <label htmlFor="email">Email Address*</label>
          <input type="email" id="email" name="email" required />

          <label htmlFor="message">Message*</label>
          <textarea id="message" name="message" required></textarea>

          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
