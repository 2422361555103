import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ClassLists.css";
import { useNavigate } from "react-router-dom";

const ClassLists = () => {
  const [events, setEvents] = useState([]);
  const [showPastClasses, setShowPastClasses] = useState(false);
  const [sortAlphabetically, setSortAlphabetically] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const eventsEndpoint = `https://www.ptemployment.org/api/classes/class-schedules/`;

    axios
      .get(eventsEndpoint)
      .then((response) => {
        const sortedEvents = response.data.sort((a, b) => {
          const dateA = new Date(a.start_time);
          const dateB = new Date(b.start_time);
          return dateA - dateB;
        });
        setEvents(sortedEvents);
      })
      .catch((error) => console.error("Error fetching events:", error));
  }, []);

  const toggleShowPastClasses = () => {
    setShowPastClasses(!showPastClasses);
  };

  const handleUserListNavigation = (event) => {
    navigate(`/userList/${event.id}`, { state: { eventDetails: event } });
  };

  const toggleSortAlphabetically = () => {
    setSortAlphabetically(!sortAlphabetically);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const currentTime = new Date();
  const displayedEvents = events
    .filter(
      (event) => showPastClasses || new Date(event.start_time) > currentTime
    )
    .sort((a, b) => {
      if (sortAlphabetically) {
        return a.title.localeCompare(b.title);
      }
      return new Date(a.start_time) - new Date(b.start_time);
    })
    .filter((event) => {
      const eventDateStr = new Date(event.start_time)
        .toLocaleDateString()
        .toLowerCase();
      const eventTimeStr = new Date(event.start_time)
        .toLocaleTimeString()
        .toLowerCase();
      return (
        event.title.toLowerCase().includes(searchQuery) ||
        eventDateStr.includes(searchQuery) ||
        eventTimeStr.includes(searchQuery)
      );
    });

  return (
    <div className="class-lists-container">
      {/* "Back to Profile" Button */}
      <div className="mb-3">
        <button
          onClick={() => navigate("/userProfile")}
          className="btn btn-primary"
        >
          Back to Profile
        </button>
      </div>
      <h1 className="class-lists-title">Class Lists</h1>
      {/* Updated note for users with the `.disclaimer` class */}
      <div className="disclaimer">
        <strong>Note:</strong> You can search for classes by name or by date.
        When searching by date, please use the format: <em>month day, year</em>
        (e.g., "January 31, 2024" would be "01/31/2024").
      </div>
      <div className="class-lists-controls mb-3">
        <button className="btn btn-info me-2" onClick={toggleShowPastClasses}>
          {showPastClasses ? "Hide Previous Classes" : "Show Previous Classes"}
        </button>
        <button
          className="btn btn-secondary me-2"
          onClick={toggleSortAlphabetically}
        >
          {sortAlphabetically ? "Organize By Date" : "Organize Alphabetical"}
        </button>
        <input
          type="text"
          className="form-control d-inline w-auto"
          placeholder="Search classes by name or date..."
          onChange={handleSearchChange}
        />
      </div>
      {displayedEvents.map((event) => (
        <div key={event.id} className="event-item">
          <span className="event-info">
            {event.title} - {new Date(event.start_time).toLocaleDateString()}{" "}
            {new Date(event.start_time).toLocaleTimeString()}
          </span>
          <button
            className="user-list-button"
            onClick={() => handleUserListNavigation(event)}
          >
            User List
          </button>
        </div>
      ))}
    </div>
  );
};

export default ClassLists;
