import React, { useState, useEffect } from "react";
import {
  format,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  getDay,
  addMonths,
  subMonths
} from "date-fns";

const RecurringDatePicker = ({ onDateSelect, selectedDates }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentMonthDays, setCurrentMonthDays] = useState([]);
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  useEffect(() => {
    const start = startOfMonth(currentMonth);
    const end = endOfMonth(currentMonth);
    const days = eachDayOfInterval({ start, end });
    setCurrentMonthDays(days);
  }, [currentMonth]);

  const handlePrevMonth = () => {
    setCurrentMonth((prev) => subMonths(prev, 1));
  };

  const handleNextMonth = () => {
    setCurrentMonth((prev) => addMonths(prev, 1));
  };

  const handleDayClick = (date, event) => {
    event.preventDefault();
    onDateSelect(date);
  };

  const renderDaysOfWeekHeader = () => {
    return daysOfWeek.map((day) => (
      <div key={day} className="calendar-header-cell">
        {day}
      </div>
    ));
  };

  const renderCalendarCells = () => {
    const cells = [];
    let emptyCellsCount = getDay(currentMonthDays[0]);

    for (let i = 0; i < emptyCellsCount; i++) {
      cells.push(
        <div key={`empty-${i}`} className="calendar-cell empty"></div>
      );
    }

    currentMonthDays.forEach((day) => {
      const formattedDay = format(day, "yyyy-MM-dd");

      cells.push(
        <button
          key={formattedDay}
          className={`calendar-cell ${
            selectedDates.includes(formattedDay) ? "selected" : ""
          }`}
          onClick={(e) => handleDayClick(formattedDay, e)}
        >
          {format(day, "d")}
        </button>
      );
    });

    return cells;
  };

  return (
    <div className="recurring-date-picker">
      <div className="calendar-controls">
        <button className="control-button" onClick={handlePrevMonth}>
          &lt; Prev
        </button>
        <span className="month-label">{format(currentMonth, "MMMM yyyy")}</span>
        <button className="control-button" onClick={handleNextMonth}>
          Next &gt;
        </button>
      </div>
      <div className="calendar-grid">
        {renderDaysOfWeekHeader()}
        {renderCalendarCells()}
      </div>
    </div>
  );
};

export default RecurringDatePicker;
