import React, { useEffect, useState } from "react";
import axios from "axios";
import { format, isAfter, isBefore, addDays, startOfDay } from "date-fns";
import "./MyClasses.css";
import { useNavigate } from "react-router-dom";

const MyClasses = () => {
  const [classes, setClasses] = useState([]);
  const [showPastClasses, setShowPastClasses] = useState(false);
  const [organizeAlphabetically, setOrganizeAlphabetically] = useState(false);
  const [showNextSevenDays, setShowNextSevenDays] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserClasses = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("No token found");
        return;
      }

      const userClassesEndpoint = `https://www.ptemployment.org/api/user-classes`;

      try {
        const response = await axios.get(userClassesEndpoint, {
          headers: { Authorization: `Bearer ${token}` }
        });

        if (Array.isArray(response.data)) {
          const filteredAndSortedClasses = response.data
            .filter((classItem) => {
              const startTime = new Date(classItem.start_time);
              return (
                isAfter(startTime, startOfDay(new Date())) ||
                (isBefore(startTime, startOfDay(new Date())) && showPastClasses)
              );
            })
            .sort((a, b) =>
              organizeAlphabetically
                ? (a.title || "").localeCompare(b.title || "")
                : new Date(a.start_time) - new Date(b.start_time)
            )
            .filter((classItem) =>
              showNextSevenDays
                ? isBefore(
                    new Date(classItem.start_time),
                    addDays(new Date(), 7)
                  )
                : true
            )
            .filter(
              (classItem) =>
                (classItem.title || "")
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                format(new Date(classItem.start_time || new Date()), "PP")
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
            );

          setClasses(filteredAndSortedClasses);
        } else {
          setClasses([]);
        }
      } catch (error) {
        console.error("Error fetching user classes:", error.response || error);
      }
    };

    fetchUserClasses();
  }, [showPastClasses, organizeAlphabetically, showNextSevenDays, searchQuery]);

  const togglePastClasses = () => setShowPastClasses(!showPastClasses);
  const toggleOrganizeAlphabetically = () =>
    setOrganizeAlphabetically(!organizeAlphabetically);
  const toggleShowNextSevenDays = () =>
    setShowNextSevenDays(!showNextSevenDays);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <div className="class-lists-container">
      <button
        onClick={() => navigate("/userProfile")}
        className="btn btn-primary mb-3"
      >
        Back to Profile
      </button>
      <h1 className="class-lists-title">My Classes</h1>
      <div className="my-classes-controls">
        <button className="btn btn-info" onClick={togglePastClasses}>
          {showPastClasses ? "Hide Past Classes" : "Show Past Classes"}
        </button>
        <button
          className="btn btn-success ms-2"
          onClick={toggleOrganizeAlphabetically}
        >
          {organizeAlphabetically
            ? "Organize by Date"
            : "Organize Alphabetically"}
        </button>
        <button
          className="btn btn-warning ms-2"
          onClick={toggleShowNextSevenDays}
        >
          {showNextSevenDays ? "Show All Classes" : "Show Next 7 Days"}
        </button>
        <div className="search-bar-container">
          <input
            type="text"
            className="search-bar"
            placeholder="Search classes by name or date..."
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <div className="class-lists">
        {classes.length > 0 ? (
          classes.map((classItem, index) => (
            <div key={index} className="event-item">
              <span className="event-info">
                {classItem.title || "Untitled Class"} -{" "}
                {format(
                  new Date(classItem.start_time || new Date()),
                  "MMMM do, yyyy"
                )}{" "}
                at{" "}
                {format(
                  new Date(classItem.start_time || new Date()),
                  "hh:mm a"
                )}
              </span>
            </div>
          ))
        ) : (
          <p className="text-center">No classes found.</p>
        )}
      </div>
      <button
        onClick={() => navigate("/userProfile")}
        className="btn btn-primary mb-3"
      >
        Back to Profile
      </button>
    </div>
  );
};

export default MyClasses;
