import React from "react";
import "./MissionSection.css";
import { useNavigate } from "react-router-dom";

const MissionSection = () => {
  const navigate = useNavigate();

  return (
    <section className="mission-section">
      <div className="headline-container">
        <div className="headline-lines"></div>
        <h2>Our Mission</h2>
        <div className="headline-lines"></div>
      </div>
      <div className="large-text">
        <p>We believe ALL people are employable.</p>
        <p>
          Pathways to Employment provides a sense of purpose, community, and
          independence to developmentally disabled adults through employment,
          life, and social skills training.
        </p>
      </div>
      <div className="features-section">
        <div className="headline-container">
          <div className="headline-lines"></div>
          <h2>Explore Our Features</h2>
          <div className="headline-lines"></div>
        </div>
        <p className="features-description">
          Discover the full potential of our services by exploring our Monthly
          Schedule for upcoming events and browsing our Class Descriptions to
          find the right fit for your learning and development needs.
        </p>
        <div className="features-buttons">
          <button
            onClick={() => navigate("/monthlySchedule")}
            className="features-button"
          >
            Monthly Schedule
          </button>
          <button
            onClick={() => navigate("/services")}
            className="features-button"
          >
            Class Descriptions
          </button>
        </div>
      </div>
    </section>
  );
};

export default MissionSection;
