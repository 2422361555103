import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "./Modal";
import "./Services.css";
import "./Classes.css";
import { useNavigate } from "react-router-dom";

const Services = () => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [eventMap, setEventMap] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isLoggedIn = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    const eventsEndpoint =
      "https://www.ptemployment.org/api/classes/class-schedules";
    axios
      .get(eventsEndpoint)
      .then((response) => {
        console.log("API response:", response.data);
        const groupedEvents = groupEventsByTitle(response.data);
        console.log("Grouped events:", groupedEvents);
        setEventMap(groupedEvents);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  }, []);

  const groupEventsByTitle = (eventsArray) => {
    return eventsArray.reduce((acc, event) => {
      const title = event.title.trim();
      if (!acc[title]) {
        acc[title] = { ...event, dates: [event.start_time], ids: [event.id] };
      } else {
        acc[title].dates.push(event.start_time);
        acc[title].ids.push(event.id);
      }
      return acc;
    }, {});
  };

  const transformEventsToMap = (eventsArray) => {
    return eventsArray.reduce((acc, event) => {
      if (!acc[event.title]) {
        acc[event.title] = { ...event, dates: [event.class_time] };
      } else {
        acc[event.title].dates.push(event.class_time);
      }
      return acc;
    }, {});
  };

  const getEventsToDisplay = () => {
    const eventsArray = Object.values(eventMap);
    if (activeCategory === "All") {
      return eventsArray;
    }
    return eventsArray.filter((event) => event.category === activeCategory);
  };

  const openModal = (eventItem) => {
    const dates = Array.isArray(eventItem.dates) ? eventItem.dates : [];
    const ids = Array.isArray(eventItem.ids) ? eventItem.ids : [];

    const datesToIdsMapping = dates.reduce((acc, date, index) => {
      const eventId = ids[index];
      acc[date] = eventId;
      return acc;
    }, {});
    console.log("Dates to IDs Mapping:", datesToIdsMapping);

    const enhancedClassInfo = {
      ...eventItem,
      datesToIds: datesToIdsMapping
    };

    console.log("Selected event for modal:", enhancedClassInfo);
    setSelectedEvent(enhancedClassInfo);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  return (
    <div>
      <div className="back-to-profile-container mb-4">
        <div className="headline-container">
          <div className="headline-lines"></div>
          <h2>Class Description</h2>
          <div className="headline-lines"></div>
        </div>
        {isLoggedIn ? (
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        ) : (
          <p className="login-message">
            You must be logged in to sign up for classes.{" "}
            <span
              onClick={() => navigate("/login")}
              style={{ color: "#007bff", cursor: "pointer" }}
            >
              Login here
            </span>
          </p>
        )}
      </div>

      <div className="class-container">
        {getEventsToDisplay().map((eventItem) => (
          <div
            key={eventItem.id}
            className="class-item"
            onClick={() => openModal(eventItem)}
          >
            <img
              src={eventItem.image_url}
              alt={eventItem.title}
              className="class-image"
            />
            <h3>{eventItem.title}</h3>
            <p>
              Price: {eventItem.price === 0 ? "Free" : `$${eventItem.price}`}
            </p>
          </div>
        ))}
      </div>

      <section className="services-section">
        <div className="headline-container">
          <div className="headline-lines"></div>
          <h2>Monthly Schedule</h2>
          <div className="headline-lines"></div>
        </div>
        <div className="large-text">
          <p>
            Check out our{" "}
            <span
              onClick={() => navigate("/monthlySchedule")}
              style={{ color: "#007bff", cursor: "pointer" }}
            >
              Monthly Schedule
            </span>{" "}
            for the latest classes and events.
          </p>
        </div>
      </section>
      {isModalOpen && <Modal classInfo={selectedEvent} onClose={closeModal} />}
      {isLoggedIn && (
        <div className="back-to-profile-bottom mt-4">
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        </div>
      )}
    </div>
  );
};

export default Services;
