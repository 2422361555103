import React from "react";
import "./Donate.css";
import "./DonateProvide.css";

const Donate = () => {
  return (
    <div className="donate-container">
      <div className="donate-message-container">
        <div className="donate-text">
          <h2>Donate!</h2>
          <p>
            Help us invest in social change! We empower young adults with
            developmental disabilities by providing them with the skills
            necessary for employment and volunteer opportunities. Our
            organization believes in the employment potential of all individuals
            and is committed to promoting social change by offering a sense of
            purpose, community, and independence. Your donation empowers adults
            with disabilities by giving them the opportunity to have a job.
          </p>
        </div>
        <div className="donate-image">
          <img src="/Assets/Photos/Donate/gbg2.jpg" alt="Donate" />
        </div>
      </div>

      <div className="donation-section">
        <h1>Your Donation Will Provide...</h1>
        <div className="donation-bg-image">
          <img
            src="/Assets/Photos/Donate/donate.webp"
            alt="Donation Background"
          />
        </div>
        <ul className="donation-impact">
          <li data-amount="$40">$40 - Covers one instructional hour.</li>
          <li data-amount="$150">
            $150 - Covers one month of insurance coverage.
          </li>
          <li data-amount="$250">
            $250 - Covers class supplies for up to 1 month.
          </li>
          <li data-amount="$1,500">
            $1,500 - Covers one 12-week class curriculum.
          </li>
        </ul>
      </div>

      {/* Donorbox Donation Form */}
      <div className="donorbox-form">
        <iframe
          src="https://donorbox.org/embed/donate-497"
          name="donorbox"
          allowpaymentrequest="allowpaymentrequest"
          seamless="seamless"
          frameBorder="0"
          scrolling="no"
          height="900px"
          width="100%"
          style={{
            maxWidth: "500px",
            minWidth: "250px",
            maxHeight: "none!important"
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default Donate;
