import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Invoicing.css";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Invoicing = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [events, setEvents] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment().startOf("month"));
  const [billingCode, setBillingCode] = useState("");
  const [isEditingBillingCode, setIsEditingBillingCode] = useState(false);
  const [emailFieldVisible, setEmailFieldVisible] = useState(false);
  const [emails, setEmails] = useState(["info@pathwaystoemploymentgb.org"]);
  const [newEmail, setNewEmail] = useState("");
  const [scheduleDate, setScheduleDate] = useState(null); // Use for date and time picker
  const [showScheduleSection, setShowScheduleSection] = useState(false); // To hide/show schedule section

  const location = useLocation();
  const token = location.state?.token || localStorage.getItem("token");

  // Fetch users and set full names
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (!token) {
          console.error("No JWT token found. Authentication failed.");
          return;
        }

        const response = await axios.get("/api/users", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const usersWithFullNames = await Promise.all(
          response.data.map(async (user) => {
            const userId = user.user_id || user.id;
            const participationResponse = await axios.get(
              `/api/check-form-completion?user_id=${userId}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );
            const { firstName, lastName } = participationResponse.data;
            return {
              ...user,
              first_name: firstName,
              last_name: lastName
            };
          })
        );

        setUsers(usersWithFullNames);
      } catch (error) {
        console.error("Error fetching users or participation details:", error);
      }
    };

    fetchUsers();
  }, [token]);

  // Handle user selection
  const handleUserSelect = async (user) => {
    const userId = user.user_id || user.id;
    const selectedUserWithFullName = {
      ...user,
      fullName:
        `${user.first_name || ""} ${user.last_name || ""}`.trim() || user.email
    };
    setSelectedUser(selectedUserWithFullName);

    if (!token) {
      console.error("No valid JWT token.");
      return;
    }

    try {
      // Fetch events for the selected user
      const eventsResponse = await axios.get(`/api/users/${userId}/events`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      // Handle billing code separately
      const billingCodeResponse = await axios.get(
        `/api/billing-code/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const billingCode = billingCodeResponse.data.billing_code || "N/A";
      setBillingCode(billingCode);

      const updatedEvents = eventsResponse.data.map((event) => {
        const billingHours =
          (new Date(event.end_time) - new Date(event.start_time)) /
          (1000 * 60 * 60);
        return {
          ...event,
          billing_hours: billingHours.toFixed(2)
        };
      });

      setEvents(updatedEvents);
      setEmails([user.email, "info@pathwaystoemploymentgb.org"]);
    } catch (error) {
      console.error("Error fetching events or billing code:", error);
    }
  };

  // Function to send invoices to all users
  const sendAllInvoicesNow = async () => {
    if (!token) {
      alert("No JWT token found. Authentication failed.");
      return;
    }

    try {
      const response = await axios.post(`/api/send-invoices-all`, null, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 200) {
        alert("All invoices sent successfully!");
      } else {
        alert("Failed to send all invoices. Please try again.");
      }
    } catch (error) {
      console.error("Error sending all invoices:", error);
      alert("There was an error sending all invoices. Please try again.");
    }
  };

  // Function to schedule invoices
  const scheduleInvoices = async () => {
    if (!token || !scheduleDate) {
      alert("No JWT token or schedule date found. Please select a valid date.");
      return;
    }

    try {
      const scheduleTime = {
        date: scheduleDate
      };

      const response = await axios.post(
        "/api/schedule-invoices",
        scheduleTime,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.status === 200) {
        alert("Invoices scheduled successfully!");
      } else {
        alert("Failed to schedule invoices. Please try again.");
      }
    } catch (error) {
      console.error("Error scheduling invoices:", error);
      alert("There was an error scheduling invoices. Please try again.");
    }
  };

  // Save billing code to both tables
  const saveBillingCode = async () => {
    if (!selectedUser) return;

    try {
      const userId = selectedUser.user_id || selectedUser.id;

      await axios.put(
        `/api/users/${userId}/billing-code`,
        { billing_code: billingCode },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      await axios.put(
        `/api/user-event-billing-info/${userId}/billing-code`,
        { billing_code: billingCode },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      alert("Billing code updated successfully in both tables.");
    } catch (error) {
      console.error("Error updating billing code:", error);
      alert("There was an error updating the billing code. Please try again.");
    }
  };

  // Handle month change
  const handleMonthChange = (increment) => {
    setCurrentMonth(currentMonth.clone().add(increment, "months"));
  };

  // Preview invoice PDF
  const previewInvoice = async () => {
    if (!token) {
      alert("No JWT token found. Authentication failed.");
      return;
    }

    try {
      const userId = selectedUser?.user_id || selectedUser?.id;

      if (!userId) {
        alert("No user selected. Please select a user to preview the invoice.");
        return;
      }

      const eventsResponse = await axios.get(`/api/users/${userId}/events`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const updatedEvents = eventsResponse.data.map((event) => {
        const billingHours =
          (new Date(event.end_time) - new Date(event.start_time)) /
          (1000 * 60 * 60);
        return {
          ...event,
          billing_hours: billingHours.toFixed(2)
        };
      });

      const payload = {
        user_id: userId,
        userInfo: {
          first_name: selectedUser.first_name,
          last_name: selectedUser.last_name,
          email: selectedUser.email,
          billing_code: billingCode
        },
        events: updatedEvents
      };

      const response = await axios.post(`/api/invoicing/invoice-pdf`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        },
        responseType: "blob"
      });

      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      window.open(pdfUrl);
    } catch (error) {
      console.error("Error previewing invoice:", error);
      alert("There was an error previewing the invoice. Please try again.");
    }
  };

  // Get events for the current month
  const getEventsForCurrentMonth = () => {
    return events.filter((event) => {
      const eventMonth = moment(event.start_time).startOf("month");
      return eventMonth.isSame(currentMonth, "month");
    });
  };

  // Send invoice via email
  const sendInvoiceNow = async () => {
    if (!token) {
      alert("No JWT token found. Authentication failed.");
      return;
    }

    try {
      const userId = selectedUser?.user_id || selectedUser?.id;

      if (!userId) {
        alert("No user selected. Please select a user to send the invoice.");
        return;
      }

      const response = await axios.post(
        `/api/users/${userId}/send-invoice`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (response.status === 200) {
        alert("Invoice sent successfully!");
      } else {
        alert("Failed to send invoice. Please try again.");
      }
    } catch (error) {
      console.error("Error sending invoice:", error);
      alert("There was an error sending the invoice. Please try again.");
    }
  };

  const addEmail = () => {
    if (newEmail && !emails.includes(newEmail)) {
      setEmails([...emails, newEmail]);
      setNewEmail("");
    }
  };

  const removeEmail = (email) => {
    if (email !== "info@pathwaystoemploymentgb.org") {
      setEmails(emails.filter((e) => e !== email));
    }
  };

  return (
    <div className="invoicing-container container mt-4">
      <h2 className="mb-4">Invoicing</h2>

      {/* Button to show the scheduling section */}
      <button
        className="btn btn-info mb-3"
        onClick={() => setShowScheduleSection(!showScheduleSection)}
      >
        {showScheduleSection
          ? "Hide Invoicing Schedule"
          : "Set Monthly Invoicing for All Users"}
      </button>

      {/* Schedule Invoices Section (Initially Hidden) */}
      {showScheduleSection && (
        <div className="schedule-invoices-section card card-body mb-4">
          <h3>Select Date and Time for Monthly Invoicing</h3>

          <div className="row">
            <div className="col-md-8">
              <label>Select Date and Time</label>
              <DatePicker
                selected={scheduleDate}
                onChange={(date) => setScheduleDate(date)}
                showTimeSelect
                dateFormat="Pp"
                className="form-control"
                placeholderText="Choose a date and time"
              />
            </div>

            <div className="col-md-4 align-self-end">
              <button
                className="btn btn-primary"
                onClick={scheduleInvoices}
                style={{ width: "100%" }}
              >
                Schedule Invoices
              </button>
            </div>
          </div>

          <div className="mt-3">
            <button className="btn btn-success" onClick={sendInvoiceNow}>
              Send All Invoices Now
            </button>
          </div>
        </div>
      )}

      <div className="user-list mb-4">
        <h3>Users</h3>
        <ul className="list-group">
          {users.map((user) => {
            const displayName =
              user.fullName ||
              user.name?.trim() ||
              `${user.first_name || ""} ${user.last_name || ""}`.trim() ||
              user.email ||
              "Unnamed User";

            return (
              <li
                key={user.user_id || user.id}
                className="list-group-item list-group-item-action"
                onClick={() => handleUserSelect(user)}
              >
                <span>{displayName}</span>
              </li>
            );
          })}
        </ul>
      </div>

      {selectedUser && (
        <div className="user-invoices">
          <h3>{selectedUser.fullName || selectedUser.email}'s Events</h3>

          {/* Email Info Button */}
          <button
            className="btn btn-info mb-3"
            onClick={() => setEmailFieldVisible(!emailFieldVisible)}
          >
            {selectedUser.fullName}'s Email Info
          </button>

          {emailFieldVisible && (
            <div className="card card-body mb-3">
              <ul className="list-group mb-3">
                {emails.map((email, index) => (
                  <li
                    key={index}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    {email}
                    {email !== "info@pathwaystoemploymentgb.org" && (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => removeEmail(email)}
                      >
                        Delete
                      </button>
                    )}
                  </li>
                ))}
              </ul>
              <div className="input-group mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Add new email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
                <button className="btn btn-primary" onClick={addEmail}>
                  Add Email
                </button>
              </div>
              <button className="btn btn-success" onClick={sendInvoiceNow}>
                Send Invoice Now
              </button>
            </div>
          )}

          {/* Update Billing Code Button */}
          <button
            className="btn btn-info mb-3"
            onClick={() => setIsEditingBillingCode(!isEditingBillingCode)}
          >
            {isEditingBillingCode
              ? `Cancel Update Billing Code for ${selectedUser.fullName}`
              : `Update Billing Code for ${selectedUser.fullName}`}
          </button>

          {isEditingBillingCode && (
            <div className="card card-body mb-3">
              <div className="form-group">
                <label>Billing Code</label>
                <input
                  type="text"
                  value={billingCode}
                  onChange={(e) => setBillingCode(e.target.value)}
                  className="form-control"
                />
              </div>
              <button className="btn btn-success" onClick={saveBillingCode}>
                Save Billing Code
              </button>
            </div>
          )}

          {/* Month Navigation */}
          <div className="month-navigation mb-4 d-flex justify-content-between">
            <button
              className="btn btn-outline-primary"
              onClick={() => handleMonthChange(-1)}
            >
              Previous Month
            </button>
            <span className="current-month h4 my-auto">
              {currentMonth.format("MMMM YYYY")}
            </span>
            <button
              className="btn btn-outline-primary"
              onClick={() => handleMonthChange(1)}
            >
              Next Month
            </button>
          </div>

          {getEventsForCurrentMonth().length > 0 && (
            <>
              <div className="d-flex justify-content-around mb-3">
                <button
                  className="btn btn-primary"
                  onClick={previewInvoice}
                  style={{ width: "100%" }}
                >
                  Preview {currentMonth.format("MMMM YYYY")} Invoice PDF
                </button>
              </div>

              {/* Events for the Month */}
              <div className="accordion" id="invoiceAccordion">
                {getEventsForCurrentMonth().map((event) => (
                  <div key={event.event_id}>
                    <button
                      className="btn btn-light btn-block text-left"
                      type="button"
                    >
                      {event.title} -{" "}
                      {moment(event.start_time).format("MM/DD/YYYY")}
                    </button>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Invoicing;
