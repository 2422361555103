import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Register.css";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [username, setUsername] = useState("");
  const [registerStatus, setRegisterStatus] = useState("");
  const [countdown, setCountdown] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (countdown === 0) {
      navigate("/login");
    } else if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countdown, navigate]);

  const handleRegister = async (event) => {
    event.preventDefault();
    const registerEndpoint = "https://www.ptemployment.org/api/auth/register";

    try {
      const response = await fetch(registerEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ email, password, username })
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to register");
      }

      setRegisterStatus("Successfully registered. Redirecting to login...");
      setCountdown(5); // Set countdown to 5 seconds
    } catch (error) {
      console.error("Registration failed:", error);
      setRegisterStatus("Registration failed. Please try again.");
    }
  };

  return (
    <div className="register-wrapper">
      <button
        className="btn btn-success back-to-login-button"
        onClick={() => navigate("/login")}
      >
        Back to Login
      </button>
      <div className="register-container">
        <h1>Register</h1>
        <form onSubmit={handleRegister}>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">Register</button>
        </form>
        {registerStatus && (
          <p>
            {registerStatus}
            {countdown !== null && ` Redirecting to login in ${countdown}...`}
          </p>
        )}
      </div>
    </div>
  );
};

export default Register;
