import React from "react";
import "./DonationSection.css";
import { useNavigate } from "react-router-dom";

const DonationSection = () => {
  const navigate = useNavigate();

  return (
    <div className="donation-section">
      <div className="headline-lines"></div>
      <h2>Donate</h2>
      <div className="headline-lines"></div>

      <p>
        Your support makes a big difference in the lives of many. For more
        detailed information on how you can help,{" "}
        {/* Use navigate function for button click navigation */}
        <button onClick={() => navigate("/donate")}>click here</button> to visit
        our Donations page.
      </p>
      <div className="donorbox-form">
        <iframe
          src="https://donorbox.org/embed/donate-497"
          name="donorbox"
          allowpaymentrequest="allowpaymentrequest"
          seamless="seamless"
          frameBorder="0"
          scrolling="no"
          height="900px"
          width="100%"
          style={{
            maxWidth: "500px",
            minWidth: "250px",
            maxHeight: "none!important"
          }}
        ></iframe>
      </div>
    </div>
  );
};

export default DonationSection;
